.module-about-cards {
	.module-about-cards__title {
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
		font-size: 40px;
		font-weight: 800;
		color: $brand-purple;
	}
	hr {
		margin-bottom: 40px;
		border-color: $brand-light-blue;
		border-width: 3px;
	}
}
