.alert {
    margin-top: 0;
}

.alert-banner {
    .alert-banner--text {
        margin-bottom: 0;
    }
    .alert-banner--content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .closebtn {
        float: unset;
    }
    @include respond-below(md) {
        .alert-banner--content {
            text-align: center;
        }
    }
}