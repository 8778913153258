.module-featured-text-image {
	position: relative;
	h1 {
		color: $brand-purple;
		font-size: 50px;
		font-weight: 800;
		margin-bottom: 30px;
	}
	p {
		color: $gray-text;
	}
	.image-featured {
		position: absolute;
		right: -10%;
		top: 0;
		img {
			width: 700px;
		}
		@include respond-below(md) {
			position: relative;
			right: auto;
		}
	}
	.module-color-block {
		h1 {
			margin-bottom: 0;
		}
		.module-color-block__content {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
