.hero-slider {
    #hero-slider.loading{
        position: relative;
        background-color: $brand-purple-hover;
        min-height: 90vh;
        &:after{
            opacity: 0.4;
            content:"";
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: 25px;
            margin-left: -30px;
            width: 60px;
            height: 60px;
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='60px' height='60px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='1' fill='%23fff' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z' /%3E%3Cpath fill='%23736190' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite' /%3E%3C/path%3E%3C/svg%3E");
        }
        .container{
            display: none;
        }
    }
    .carousel-content {
        background-color: $brand-purple-transparent!important;
    }
    .owl-carousel {
        .carousel-content {
            padding: 64px 32px;
            bottom: 25%;
            h4 {
                font-weight: 400;
                font-size: 56px;
                line-height: 1.2;
            }
            p {
                font-size: 20px;
            }
        }
        .owl-item {
            img {
                height: 720px
            }
        }
    }
}

.career-banner-div {
    margin-top: 0;
    z-index: 0;
    .career-banner {
        padding-top: 600px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}