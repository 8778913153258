﻿.subsite-menu--categories {
    .subsite-menu--categories--container {
        padding: 0px;
        height: 53px;
    }

    .subsite-menu--category {
        color: white !important;
    }

    .subsite-menu--category[data-site-category="subsite-category-early_childhood_education"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-early_childhood_education"]:hover {
        color: #ffffff !important;
        background-color: #4abab8;
    }

    .subsite-menu--category[data-site-category="subsite-category-special_education__disability_services"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-special_education__disability_services"]:hover {
        background-color: #e23d3b !important;
    }

    .subsite-menu--category[data-site-category="subsite-category-behavior_health"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-behavior_health"]:hover {
        background-color: #ffbb00 !important;
    }

    .subsite-menu--category[data-site-category="subsite-category-community__social"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-community__social"]:hover {
        background-color: #b7805c !important;
    }

    .subsite-menu--category[data-site-category="subsite-category-student_success"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-student_success"]:hover {
        background-color: #f5865c !important;
    }

    .subsite-menu--category[data-site-category="subsite-category-fitness__health"].category_is_active,
    .subsite-menu--category[data-site-category="subsite-category-fitness__health"]:hover {
        background-color: #37a564 !important;
    }
}
