.home-department-cards {
    margin-bottom: 20px;
    .card {
        margin-bottom: 0!important;
    }
    .card-content {
        display: flex;
        flex-wrap: nowrap;
        .card-content--image {
            min-width: 240px;
            position: relative;
            overflow: hidden;
            .card-img-top {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            @media screen and (max-width: 992px) {
                min-height: 240px;
                .card-img-top {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .card-body {
        .list-group-flush {

            padding: 0;

            .list-group-item {
                padding: 12px 0;
                line-height: unset!important;
                font-size: 16px;
                &.has-sublinks {
                    border-bottom: none;
                    font-size: 18px;
                    padding-bottom: 0!important;
                    &:hover {
                        background-color: #fff!important;
                    }
                    .arrow {
                        display: none;
                    }
                    &.has-1-column {
                        .arrow {
                            display: inline-block;
                        }
                    }
                }
            }

            .submenu-links {
                display: flex;
                flex-direction: column;
                &.has-1-column {
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-left: 26px;
                    .list-group-item.list-group-item--sublink {
                        padding-right: 4px!important;
                        padding-top: 4px!important;
                        border: none;
                        padding-left: 0!important;
                        .arrow {
                            display: none;
                            border: none;
                        }
                        .list-group-item--sublink--inner-text {
                            transition: all 0.3s ease;
                            &:after {
                                content: ', ';
                            }
                        }
                        &:last-of-type {
                            span {
                                &:after {
                                    content: '';
                                }
                            }
                        }
                        &:hover {
                            background-color: #fff!important;
                            a {
                                color: $accent-blue;
                            }
                        }
                    }
                }
            }

            .list-group-item--sublink {
                border-width: 0 0 1px;
                padding-left: 24px!important;
                color: $brand-blue;
                    .list-group-item--sublink--inner-text {
                        font-weight: 600;
                    }
            }

        } 
        .card-content--lists {
            padding: 40px 24px 60px;
            .cart-dec {
                padding: 0;
            }
        }

    }
}