.list-group--item {
    .list-group--item-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: $brand-purple;
        font-weight: bold;
        transition: all 0.3s ease;
        cursor: pointer;
        a, p {
            color: $brand-purple;
            font-weight: 800;
            transition: all 0.3s ease;
        }
        a:hover {
            color: $brand-purple-hover;
        }
        .arrow.right {
            transform: rotate(45deg);
            transition: transform 0.3s ease;
        }
        &.collapsed {
            .arrow.right {
                transform: rotate(-45deg);
            }
        }
    }
    .list-group--item-description {
        font-size: 16px;
    }
}