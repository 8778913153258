html,
body {
    height: 100%;
}

html {
    font-size: 10px;
}

body {
    font-family: $standard-family;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    color: $black;
    padding-top: 93px;
    &.overflow-hidden {
        overflow: hidden;
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.container {
    width: 100%;
    max-width: $site-width;
    padding-left: $site-gutter;
    padding-right: $site-gutter;
    margin: 0 auto;
    box-sizing: border-box;

    @media #{$phone} {
        padding-left: $mobile-gutter;
        padding-right: $mobile-gutter;
    }
    &.container--narrow {
        max-width: 1200px;
    }
}

.purple-after {
    position: relative;
    margin-bottom: 40px;
    &:after {
        content: "";
        background: #37265b;
        width: 100vw;
        height: 240px;
        bottom: -60px;
        left: calc((100vw - 100%) / -2);
        position: absolute;
        z-index: -1;
    }
}

.not-found-container {
    text-align: center;
    padding: 80px 0;
    .h1 {
        font-size: 8rem;
        font-weight: 600;
        color: $brand-purple;
    }
    .not-found-body {
        font-size: 3rem;
        color: $gray-text;
    }
}