.btn {
	&.btn-primary {
		font-size: 18px;
		border: solid 2px $brand-purple;
		transition: background-color 0.2s ease, border 0.2s ease;
		&:focus,
		&:active,
		&:disabled {
			background-color: $brand-purple;
			border: solid 2px $brand-purple;
			color: #fff;
		}
		&:hover {
			background-color: $brand-blue;
			border: solid 2px $brand-blue;
		}
	}

	&.outline {
		border: 2px solid $brand-purple;
		padding: 10px 27px;
		font-weight: 700;
		font-size: 18px;
		line-height: 1;
		background: 0 0;
		color: $brand-purple;
		border-radius: 20px;
		&:hover,
		&:focus,
		&:active {
			background-color: $brand-purple;
			color: #fff;
		}
	}

	&.btn-blue {
		border: 2px solid $blue-alt;
		padding: 6px 27px;
		color: $blue-alt;
		font-size: 20px;
		border-radius: 60px;
		&:focus,
		&.selected {
			background: $blue-alt;
			color: #fff;
		}
	}
	&.btn-with-icon {
		border: 2px solid $blue-alt;
		padding: 10px 25px;
		color: $blue-alt;
		font-size: 17px;
		font-weight: 700;
		border-radius: 60px;
		img {
			margin-bottom: 12px;
		}
		&:focus,
		&.selected {
			background: $blue-alt;
			color: #fff;
		}
		&:hover {
			border: 2px solid $brand-purple;
			color: $brand-purple;
		}
	}
}
