.module-color-block {
	background-color: $brand-purple;
	.module-color-block__content {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	&.full-width {
		width: 100vw !important;
		margin-left: calc((100vw - 100%) / -2);
		margin-right: calc((100vw - 100%) / -2);
	}
	h1 {
		color: #fff !important;
		font-weight: 800;
		font-size: 30px;
		margin-bottom: 0 !important;
	}
}
