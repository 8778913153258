.site-footer {
    background-position: bottom right;
    .site-rights p {
        font-size: 16px;
    }
    .menu-item a {
        color: #635465;
        &:hover {
            color: $accent-blue;
        }
    }
}

.see-allfooter a {
    color: $accent-blue;
}

.footer-last {
    font-weight: 500;
    color: #a29fa3;
    padding: 30px 0;
    margin-top: 50px;
    border-top: solid 1px #868586;
    .menu-item {
        a {
            font-size: 16px;
            font-weight: 600;
            &:after {
                content: "/";
                padding-left: 4px;
            }
        }
        &:last-child a {
            &:after {
                display: none;
            }
        }
    }
}