.mb-6 {
	margin-bottom: 50px;
}
.card-news {
	margin-bottom: 30px;
	border: none;
	box-shadow: 0 10px 16px rgba(0, 0, 0, 0.12);
	.card-news__posttitle {
		a {
			color: $brand-purple;
			font-weight: 700;
			font-size: 25px;
		}
	}
	.card-news__imagelink {
		display: block;
		margin-bottom: 20px;
		img {
			width: 100%;
			max-height: 120px;
			object-fit: cover;
		}
	}
	.card-news__date {
		margin: 15px 0;
		font-size: 12px;
		font-weight: 700;
	}
	.card-news__excerpt {
		font-size: 15px;
		line-height: 25px;
		color: $gray-text;
	}
	.card-news__sitetitle {
		color: $blue-alt;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 800;
		letter-spacing: 1.2px;
	}
	.card-news__link {
		color: #29beff;
		font-weight: 800;
		font-size: 13px;
		background: transparent;
		padding: 0;
		margin-left: 0;
		position: relative;
		left: 0 !important;
		text-transform: capitalize;
		&:after {
			top: 2px;
			position: absolute;
			content: "";
			// background: url("images/footer-arrow.png");
			right: -16px;
			top: 2px;
			width: 8px;
			height: 13px;
			background-position: center;
			background-size: contain;
		}
	}
}
