.prof-news-evts {
  background-color: #351763;
  padding-top: 50px;
  padding-bottom: 50px;
  @include respond-below(md) {
    padding-bottom: 0;
    padding-top: 10px;

  }
}

.prof-news-evts__title-wrapper {
  margin-bottom: 40px;
  border-bottom: 3px solid #5090d6;
  width: 100%;
  @include respond-below(sm) {
    margin-top: 20px;
  }
}

.prof-news-evts__title {
  color: #fff;
  font-weight: 700;
  font-size: 44px;
  line-height: 55px;

}

.prof-news-evts__columns-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;

  @include respond-below(md){
    flex-direction: column;
  }
}

.prof-news-evts__column-news,
.prof-news-evts__column-events {
  width: 50%;
  display: block;
  @include respond-below(md) {
    width: 100%;
    margin-bottom: 60px;
  }
}

.prof-news-evts__column-events {
  @include respond-below(md) {
    padding-top: 60px;
    position: relative;
    &::after {
      background-color: #5090d6;
      height: 1px;
      width: 60%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      content: "";
    }
  }
}

.prof-news-evts__column-news {
  position: relative;
  &:after {
    width: 1px;
    height: 100%;
    content: "";
    background-color: #5090d6;
    position: absolute;
    top: 0;
    right: 0;
    @include respond-below(md) {
      display: none;
    }
  }
}

.prof-news-evts__new {
  width: 80%;
  margin-right: auto;
  margin-bottom: 40px;
  font-family: $standard-family;
  @include respond-below(md) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.prof-news-evts__evt-single-event {
  width: 95%;
  margin-left: auto;
  margin-bottom: 40px;
  font-family: $standard-family;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include respond-below(md) {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 70px;
    position: relative;
  }
}

.prof-news-evts__new-content,
.prof-news-evts__evt-content {
  width: 75%;
  margin-left: 30px;

  @include respond-below(sm) {
    margin-left: 20px;
  }
}

.prof-news-evts__new-date {
  font-size: 12px;
  line-height: 20px;
  font-family: $standard-family;
  color: #29beff;
  font-weight: 700;
}

.prof-news-evts__new-cat {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.72px;
  font-family: $standard-family;
  color: #5090d6;
  font-weight: 900;
  text-transform: uppercase;
}

.prof-news-evts__new-title {
  font-size: 26px;
  line-height: 28px;
  font-family: $standard-family;
  font-weight: 800;
  a {
    color: #fff;
  }

  @include respond-below(sm) {
    font-size: 20px;
    line-height: 22px;
  }
}

.prof-news-evts__new-excerpt {
  font-size: 16px;
  line-height: 22px;
  font-family: $standard-family;
  color: #fff;
}

.prof-news-evts__new-read-more {
  font-weight: 900;
  font-size: 16px;
  color: #29beff;
  line-height: 22px;
  font-family: $standard-family;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.prof-news-evts__new-read-more {
  display: inline-block;
}

.prof-news-evts__new-arrow {
  height: 12px;
  width: 80px;
}

.prof-news-evts__evt-title {
  font-size: 30px;
  line-height: 32px;
  font-family: $standard-family;
  font-weight: 700;
  a {
    color: #5090d6;
  }
  @include respond-below(sm) {
    line-height: 25px;
    font-size: 22px;
  }
}

.prof-news__see-all-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.prof-news__see-all {
  display: inline-block;
  margin-right: 40px;
  margin-left: auto;
  font-weight: 900;
  font-size: 16px;
  color: #29beff;
  line-height: 22px;
  font-family: $standard-family;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .prof-news__caret {
    margin-right: 13px;
  }
  .arrow {
    border-color: $accent-blue;
  }
  @include respond-below(md) {
    margin-right: 10px;

  }
}

.prof-news-evts__evt-addr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  max-width: 100%;
  word-wrap: break-word;
  transform: translateX(-6px);
  @include respond-below(sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

.prof-news-evts__evt-map-pin {
  transform: translateY(-8px);
  height: 28px;
  width: 28px;
  min-width: 28px;
  fill: #fff;
  @include respond-below(sm) {
    transform: translateY(-4px);
    
  }
}

.prof-news-evts__evt-excerpt {
  color: #fff;
  line-height: 22px;
  font-size: 16px;
  
  @include respond-below(sm) {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
}

.prof-news-evts__evt-date {
  color: #fff;
  font-size: 30px;
  line-height: 16px;
  font-weight: 800;
  display: inline-block;
  width: 80px;
  min-width: 80px;
  position: relative;
  margin-right: 20px;
  text-align: center;
  padding-top: 10px;

  span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }

  &::after {
    background-color: #5090d6;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  @include respond-below(md) {
    width: auto;
    min-width: auto;
    padding-right: 20px;
  }

  @include respond-below(sm) {
    font-size: 20px;
    line-height: 16px;
  }
}

.prof-news-evts__evt-main-content {
  @include respond-below(md) {
    margin-right: auto;
  }
}

.prof-news-evts__evt-register-btn {
  display: inline-block;
  color: #fff;
  border-color: #fff;
  text-transform: none;
  background-color: transparent;
  padding: 5px 20px;
  transition: all 300ms ease;
  font-size: 16px;
  min-width: unset;
  border-radius: 30px;
  &:hover {
    background-color: #fff;
    color: #351763;
  }

  @include respond-below(md) {
    position: absolute;
    top: 100%;
    right: 10px;
    margin-top: 10px;
  }

  @include respond-below(sm) {
    font-size: 16px;
  }
}

.prof-news-evts__evt-last-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;

  @include respond-below(sm) {
    margin-left: auto;
    
  }
  
  @include respond-below(sm) {
    align-items: flex-start;
    flex-direction: column;
  }
}