a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.entry-content {
    font-size: 16px;
    line-height: 1.33;

    * {
        &:first-child {
            margin-top: 0;
        }
    }

    a {
        color: #0357ff;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        transition: color 0.2s ease;

        &:hover {
            text-decoration: underline;
        }
    }

    p {

        margin-bottom: 16px;

        .featuredCTA & {
            margin-bottom: 4px;
        }
    }

    ul,
    ol {
        margin-bottom: 16px;
        padding-left: 32px;

        > li {
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;

        > li {

            > ul {
                list-style-type: circle;
                margin-bottom: 0;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 32px 0 16px;
        color: $black;
        font-weight: 700;
    }

    h1, .h1 {
        font-size: 36px;
        font-weight: 900;

        @media #{$phone} {
            font-size: 28px;
        }

        .featuredCTA & {
            color: #597687;
        }
    }

    h2, .h2 {
        font-size: 40px;
        line-height: 1;
        font-weight: 900;
        font-family: $alt-family;

        @media #{$phone} {
            font-size: 34px;
        }

        .featuredCTA & {
            color: #597687;
        }
    }

    h3, .h3 {
        font-size: 24px;
        font-weight: 600;

        @media #{$phone} {

        }
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .alignnone {
        margin: 16px 0;
    }

    p img.alignnone {
        margin-bottom: 0;
    }

    hr {
        border: 0;
        border-bottom: 1px solid rgba(73, 89, 102, 0.2);
        margin: 20px 0;
    }

    .table__container {
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 16px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #d3d3d3;
    }

    th,
    td {
        padding: 16px;
        text-align: center;
        vertical-align: middle;

        @media #{$phone} {
            font-size: 16px;
        }

        em, i {
            font-weight: 400;
            font-size: 16px;

            @media #{$phone} {
                font-size: 14px;
            }
        }
    }

    th {
        font-weight: 600;
        background-color: #e8e8e8;

        &:first-child {
            text-align: left;
        }
    }

    td {
        background-color: #f1f1f1;
    }

    tbody {

        tr {

            &:nth-child(even) {

                td {
                    background-color: #d6d6d6;
                }
            }
        }
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #777777;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #777777;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #777777;
}
:-moz-placeholder { /* Firefox 18- */
    color: #777777;
}

.section-heading {
    margin: 20px 0;
    padding-bottom: 18px;
    border-bottom: solid 3px $brand-light-blue;
    text-transform: uppercase;
    font-weight: 900;
    &:after {
        content: unset;
    }
}

.home {
    .section-heading {
        font-size: 29px;
        color: $grey;
    }
}

.subpage-content {
    padding-bottom: 72px;
    p {
        font-size: 18px;
        margin-top: 4px;
        margin-bottom: 16px;
        letter-spacing: 0.4px;
        line-height: 26px;
        font-weight: 400;
        color: #635465;
    }
}

.cart-dec {
    font-size: 16px;
}
