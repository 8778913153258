.home-department-cards {
	.card {
		max-width: 100%;
		flex-basis: 40%;

		&:first-child {
			max-width: 100%;
			flex-basis: 100%;
		}
	}

	.card-body .list-group-flush .list-group-item {
		margin: 0;
	}
}

.card-body .list-group-flush .list-group-item {
	position: relative;
	padding: 6px 0 6px 0;
	font-size: 15px;
	font-weight: 800;
	letter-spacing: 0.1px;
	line-height: 24px;
	border-bottom: 1px solid #a7c7ea;
}

.styled-card {
	background-color: #fff;
	padding: 60px;
	box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
	border-bottom: solid 13px $brand-purple;
}

.card {
	&.card-about {
		box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
		border-bottom: 10px solid $brand-purple;
		.subtitle {
			color: $brand-blue;
			font-size: 25px;
			margin-bottom: 0;
		}
		.title {
			color: $brand-purple;
			font-weight: 800;
			font-size: 40px;
			margin-bottom: 20px;
		}
		.card-body {
			padding-bottom: 20px !important;
			p {
				color: $gray-text;
				margin-bottom: 20px;
			}
		}
	}
}
