.form-group{
    .form-control{
        height: 40px;
        padding: 8px 10px;
        font-size: 16px;
        background-color: #fff !important;
    }
    textarea.form-control{
        height: auto;
    }
}

.form-message{
    div{
        font-size: 16px;
        margin: 0 0 15px;
        padding: 10px;
        border-radius: 6px;
        &.success{
            background: #bdecd1;
        }
        &.error{
            background: #e9b0b0;
        }
    }
}