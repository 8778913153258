//--------------------
// Structure
$site-width: 1400px;
$site-gutter: 32px;
$mobile-gutter: 20px;

//--------------------
// Media Queries
$tablet-width: 1024px;
$phone-width: 767px;
$header-width: 920px;

$desktop: 'screen and (max-width: #{$site-width})';
$tablet: 'screen and (max-width: #{$tablet-width})';
$phone: 'screen and (max-width: #{$phone-width})';
$header: 'screen and (max-width: #{$header-width})';

//For media mixins
$breakpoints: (
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px
);


//--------------------
// Other


//--------------------s
// Colors
$black: #272727;

//--------------------
// Fonts
$standard-family: 'Nunito', sans-serif;
$alt-family: 'Work Sans', sans-serif;

// Social Colors Guys, Social Colors
$twitter: #00aced;
$facebook: #3b5998;
$linkedin: #007bb6;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;
$github: #703083;
$snapchat: #333333;

$brand-purple:                  #351763;
$brand-purple-hover:            #736190;
$brand-purple-transparent:      rgba(84,56,127,0.48);
$dirty-purple:					#44394c;
$brand-blue:					#588fcb;
$brand-light-blue:				#aad3ff;
$accent-blue:                   #29BEFF;
$grey:							#A6A7A7;
$blue-alt:                      #5090d6;
$gray-text:                     #868586;