.news-section {
	.news-content {
		padding-left: 0;
		float: unset;
		width: 100%;
		.post-content-text {
			color: #37265b;
			font-size: 14px;
			padding-right: 20px;
			line-height: 16px;
			margin-bottom: 4px;
			width: 87%;
		}
	}

	.news-content--thumb {
		.attachment-post-thumbnail {
			width: 100%;
			height: 214px;
			object-fit: cover;
			object-position: center;
		}
		margin-bottom: 20px;
	}
}
