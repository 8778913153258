.card-list {
    width: 100%;
    border-bottom: none!important;
    .card-list--header {
        background-color: $brand-blue;
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        display: flex;
        justify-content: flex-end;
    }
    .card-list--header-title {
        margin-bottom: 0;
        padding: 16px;
        padding-left: 24px;
        width: 60%;
    }
    .card-list--body {
        display: flex;
        min-height: 290px;
        background-color: #fff;
    }
    .card-list--image {
        max-width: calc(40% - 36px);
        margin-top: -36px;
        min-width: 40%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .card-list--content {
        width: 60%;
        padding: 24px;
        padding-bottom: 24px!important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card-body {
            padding-bottom: 36px!important;
        }
        .cart-dec {
            padding: 0!important;
            margin-bottom: 0!important;
        }
        .list-group-flush {
            padding: 16px 0;
            .list-group-item {
                .arrow.right {
                    border-color: $accent-blue!important;
                }
            }
        }
        .card-body--cta {
            margin-top: 16px;
        }
    }
}