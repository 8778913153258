.news-events__heading-title {
  margin-bottom: 40px;
  h2 {
    color: #a6a6a6;

    &::after{
      padding-top: 20px;
      border-bottom: 3px solid #aad3ff;
    }
  }
  @include respond-below(sm) {
    margin-top: 20px;
  }
}

.news__single-new,
.events__single-event {
  overflow: hidden;
  margin-bottom: 40px;
  font-family: $standard-family;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.news__content,
.events__content {
  position: relative;
  width: 75%;
  margin-left: 30px;
  @include respond-below(sm) {
    margin-left: 20px;
  }
}

.events__content {
  padding-right: 96px;
  @include respond-below(sm) {
    padding-right: 0;
    padding-bottom: 42px;
  }
}

.news__img-container,
.events__img-container {
  // width: 155px;
}

.news__new-img,
.events__new-img {
  width: 155px;
  overflow: hidden;
  background-color: $blue-alt;
  img {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    object-fit: cover;
    object-position: center;
    height: 227px;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.75;
    }
  }
}

.news.news-col-2 {
  margin-bottom: 0;
}

.news__date {
  font-size: 12px;
  line-height: 20px;
  font-family: $standard-family;
  color: #44394c;
  font-weight: 700;
}

.news__cat {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.72px;
  font-family: $standard-family;
  color: #5090d6;
  font-weight: 900;
  text-transform: uppercase;
}

.news__title {
  font-size: 26px;
  line-height: 28px;
  font-family: $standard-family;
  font-weight: 900;
  a {
    color: #351763;
    transition: color 0.2s ease;
    &:hover {
      color: $accent-blue;
    }
  }
  @include respond-below(sm) {
    font-size: 20px;
    line-height: 22px;
  }
}

.news__excerpt {
  font-size: 16px;
  line-height: 22px;
  font-family: $standard-family;
  color: #44394c;
}

.news__read-more, 
.news__see-all {
  font-weight: 900;
  font-size: 16px;
  color: #29beff;
  line-height: 22px;
  font-family: $standard-family;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    color: $brand-purple;
  }
}

.news__caret {
  margin-left: 13px;
  height: 12px;
  width:12px;
}

.news__see-all {
  justify-content: flex-end;
  .news__caret {
    margin-left: 0;
    margin-right: 13px;
  }
}

.events__title {
  font-size: 30px;
  line-height: 30px;
  font-family: $standard-family;
  font-weight: 900;
  a {
    color: #5090d6;
  }
  @include respond-below(sm) {
    line-height: 25px;
    font-size: 22px;
  }
}

.events__addr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #44394c;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  transform: translateX(-6px);
  @include respond-below(sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

.events__map-pin {
  transform: translateY(-2px);
  height: 28px;
  width: 28px;
}

.events__excerpt {
  color: #44394c;
  line-height: 22px;
  font-size: 16px;
  
  @include respond-below(sm) {
    font-size: 14px;
    line-height: 18px;
    margin-top: 20px;
  }
}

.events__date {
  color: #351763;
  font-size: 18px;
  line-height: 30px;
  font-weight: 800;
  display: inline-block;
  span {
    font-size: 10px;
    transform: translateY(-7px);
    display: inline-block;
  }
  
  @include respond-below(sm) {
    font-size: 14px;
    line-height: 30px;
  }
}

.events__register-btn {
  display: inline-block;
  color: #351763;
  border-color: #351763;
  text-transform: none;
  background-color: transparent;
  padding: 5px 20px;
  transition: all 300ms ease;
  font-size: 18px;
  min-width: unset;
  border-radius: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  &:hover {
    background-color: #351763;
    color: #fff;
  }

  @include respond-below(sm) {
    font-size: 16px;
    left: 0;
    right: auto;
  }
}

.events__last-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond-below(sm) {
    align-items: flex-start;
    flex-direction: column;
  }
}