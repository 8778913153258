.career-sec {
    .careers-container {
        max-height: 400px;
        overflow: scroll;
        margin-bottom: 24px;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
            display: none;
        }
    }

    .career-text {
        color: $brand-light-blue;
        .career-text--title {
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
    }

    .career-see-all {
        font-size: 16px;
        .arrow {
            border-color: $accent-blue;
        }
        a {
            color: $accent-blue!important;
        }
        &:hover {
            a {
                color: #3FCCCC!important;
            }
            .arrow {
                border-color: #3FCCCC!important;
            }
        }
    }

    .career {
        padding: 12px 0;
        border-bottom: solid 1px #c09ef0;
        &:last-of-type {
            border-bottom: none;
        }
    }

    .career-type {
        font-size: 16px;
        text-transform: capitalize;
        color: $brand-light-blue;
    }

    .career-title {
        position: relative;
        .arrow {
            border-color: $accent-blue;
            position: absolute;
            top: 10px;
            left: 0;
        }
        a {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            &:hover {
                color: $accent-blue;
            }
        }

    }

    .career-location {
        color: #c09ef0;
    }

    .career-title,
    .career-type,
    .career-location {
        padding-left: 24px;
    }
    

}