@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5MZ9vHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5MZ9vOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5MZ9vFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5MZ9vEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight Italic'), local('Nunito-ExtraLightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5MZ9vKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4oZNvHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4oZNvOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4oZNvFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4oZNvEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light Italic'), local('Nunito-LightItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4oZNvKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Italic'), local('Nunito-Italic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXX3I6Li01BKofIMNaORs71cA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Italic'), local('Nunito-Italic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXX3I6Li01BKofIMNaHRs71cA.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Italic'), local('Nunito-Italic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXX3I6Li01BKofIMNaMRs71cA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Italic'), local('Nunito-Italic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXX3I6Li01BKofIMNaNRs71cA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Italic'), local('Nunito-Italic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXX3I6Li01BKofIMNaDRs4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5cYtvHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5cYtvOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5cYtvFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5cYtvEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold Italic'), local('Nunito-SemiBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN5cYtvKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN44Y9vHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN44Y9vOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN44Y9vFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN44Y9vEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold Italic'), local('Nunito-BoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN44Y9vKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4kYNvHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4kYNvOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4kYNvFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4kYNvEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold Italic'), local('Nunito-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4kYNvKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4AYdvHUT8_DQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4AYdvOUT8_DQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4AYdvFUT8_DQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4AYdvEUT8_DQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black Italic'), local('Nunito-BlackItalic'), url(https://fonts.gstatic.com/s/nunito/v13/XRXQ3I6Li01BKofIMN4AYdvKUT8.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA-seUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA-seUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA-seUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA-seUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA-seUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light'), local('Nunito-Light'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAnsSUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light'), local('Nunito-Light'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAnsSUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light'), local('Nunito-Light'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAnsSUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light'), local('Nunito-Light'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAnsSUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nunito Light'), local('Nunito-Light'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAnsSUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Regular'), local('Nunito-Regular'), url(https://fonts.gstatic.com/s/nunito/v13/XRXV3I6Li01BKofIOOaBXso.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Regular'), local('Nunito-Regular'), url(https://fonts.gstatic.com/s/nunito/v13/XRXV3I6Li01BKofIMeaBXso.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Regular'), local('Nunito-Regular'), url(https://fonts.gstatic.com/s/nunito/v13/XRXV3I6Li01BKofIOuaBXso.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Regular'), local('Nunito-Regular'), url(https://fonts.gstatic.com/s/nunito/v13/XRXV3I6Li01BKofIO-aBXso.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito Regular'), local('Nunito-Regular'), url(https://fonts.gstatic.com/s/nunito/v13/XRXV3I6Li01BKofINeaB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA6sKUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA6sKUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA6sKUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA6sKUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofA6sKUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold'), local('Nunito-Bold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAjsOUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold'), local('Nunito-Bold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAjsOUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold'), local('Nunito-Bold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAjsOUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold'), local('Nunito-Bold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAjsOUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Nunito Bold'), local('Nunito-Bold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAjsOUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAksCUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAksCUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAksCUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAksCUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAksCUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black'), local('Nunito-Black'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAtsGUbOvISTs.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black'), local('Nunito-Black'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAtsGUZevISTs.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black'), local('Nunito-Black'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAtsGUbuvISTs.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black'), local('Nunito-Black'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAtsGUb-vISTs.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Nunito Black'), local('Nunito-Black'), url(https://fonts.gstatic.com/s/nunito/v13/XRXW3I6Li01BKofAtsGUYevI.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Dosis;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/dosis/v18/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7p29NNpQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7g29NNpQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7r29NNpQ.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7q29NNpQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUOjIg1_i6t8kCHKm459WxZqh7k29M.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxC7mw9c.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRzS7mw9c.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxi7mw9c.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRxy7mw9c.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Italic'), local('Montserrat-Italic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZOg3z_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZFgrz_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZcgvz_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZbgjz_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8fZwnCo.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz-PZwnCo.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8_ZwnCo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz8vZwnCo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUPjIg1_i6t8kCHKm459WxZSgnz_PZw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxC7mw9c.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRzS7mw9c.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxi7mw9c.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRxy7mw9c.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUQjIg1_i6t8kCHKm45_QpRyS7m.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_aZA3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Montserrat Light'), local('Montserrat-Light'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gTD_u50.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3g3D_u50.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gbD_u50.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gfD_u50.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

body {
    /*color: #44394C;*/
    color: #635465;
    font-family: 'Nunito', sans-serif;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: #37265b;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .active>.nav-link {
    color: #635465;
}

.navbar-expand-lg .navbar-nav .nav-link.active:hover {
    color: #37265b;
}

.navbar-light .navbar-nav .active>.nav-link:hover {
    color: #37265b;
}

a:hover {
    color: #007bff;
}

.navbar-expand-lg .navbar-nav .nav-link:hover:after {
    content: '';
    width: 100%;
    height: 3px;
    background: #588fcb;
    display: block;
}

.navbar-expand-lg .navbar-nav .nav-link:after {
    content: '';
    width: 100%;
    height: 3px;
    background: transparent;
    display: block;
}

.navbar-expand-lg .navbar-nav .nav-link.active:after {
    background: #588fcb;
}

.navbar-expand-lg .navbar-nav #menu-item-451 .nav-link:hover:after,
.navbar-expand-lg .navbar-nav #menu-item-452 .nav-link:hover:after,
.navbar-expand-lg .navbar-nav #menu-item-453 .nav-link:hover:after {
    background: transparent;
}

.navbar-brand img {
    width: auto;
    margin-left: -9px;
    height: 57px;
    margin-top: -8px;
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}

.top-bar {
    background-color: #3A7CC4;
}

.second-level-sub-menu {
    background-color: #37265b;
}

.second-level-menu {
    background-color: #D4D4D4;
}

.heading {
    margin-bottom: 26px;
}

.main-heading {
    font-size: 61px;
    color: #635465;
}


.page-template-project-reach .main-sub-heading {
    color: #4abab8;
}

.page-template-student-services .main-sub-heading {
    color: #f5865c;
}

.main-description {
    color: #868586;
    font-size: 15px;
    padding-left: 2px;
    font-weight: 800;
    padding-right: 40px;
    letter-spacing: .2px;
    margin-bottom: 20px;
}

.main-button {
    padding: 6px 21px 6px 19px;
    background: #4b1469;
    font-size: 14px;
    border-radius: 26px;
    color: #fff;
}

.main-button:hover {
    color: #fff;
}

.youtube-player {
    margin: 0px !important;
}

.section-padding-55 {
    padding-bottom: 30px;
}

.section-heading {
    color: #a6a6a6 !important;
    font-size: 21px;
    text-transform: inherit;
    letter-spacing: .2px;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    margin-bottom: 20px;
}

.section-heading:after {
    padding-top: 19px;
}

.section-heading:after {
    border-bottom: 2px solid #4abab8;
    content: '';
    display: block;
    padding-top: 17px;
}

.page-template-project-reach .section-heading:after {
    border-bottom: 2px solid #4abab8;
}

hr.section-divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 3px solid #37C4BD;
}

.section-description {
    font-size: 13px;
    margin-top: 4px;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-weight: 400;
    color: #635465;
}

.news_pagination {
    display: none !important;
}


.news-events {
    background-color: #37265b;
    padding-top: 80px;
    padding-bottom: 80px;
}

.news-events-heading {
    font-weight: 700;
    color: white;
    color: #fff !important;
    font-size: 36px;
    text-transform: capitalize;
    margin-left: -3px;
    margin-bottom: 40px;
}

#news-events .news-more-link {
    /*position: relative;
    left: 0;*/
}

#news-events .news-more-link:hover {
    background: transparent;
}

.page-parent .news-content-excerpt a.news-more-link:after {
    background: 0;
}

a:hover {
    color: #29BEFF;
}

.news-events-heading:after {
    border-bottom: 2px solid #37C4BD;
    content: '';
    display: block;
    padding-top: 13px;
}

.news-events-divider {
    margin-top: 2rem;
    margin-bottom: 4rem;
    border: 0;
    border-top: 3px solid #588fcb;
}

.news-events .wpem-event-listings .wpem-event-layout-wrapper {
    border-bottom: unset !important;
    border-right: unset !important;
    background: unset !important;
}

.news-events .wpem-event-date {
    border-left: unset !important;
    border-right: 1px solid #588fcb;
    transition: border .2s;
    width: 66px !important;
}

.news-events .wpem-date {
    font-size: 29px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    color: white !important;
}

.news-events .wpem-month {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    color: white !important;
}

.news-events .wpem-heading-text {
    font-size: 33px !important;
    color: white !important;
}

.news-events .wpem-event-date-time {
    display: none !important;
}

.news-events .wpem-event-location::before {
    left: -4px !important;
    font-size: 15px !important;
    color: white !important;
    line-height: 23px !important;
}

.news-events .wpem-event-location-text {
    color: white !important;
}

.wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    border-left: unset !important;
    transition: unset !important;
}

.news-events form#event_filters {
    display: none !important;
}

.news-events .wpem-event-banner {
    display: none !important;
}

.news-events .news-thumb {
    display: none !important;
}

.news-events .news-col-list .news-content {
    width: 100% !important;
}

.news-events .news-col-list .news-content {
    margin-left: unset !important;
}

.news-events .grid-date-post {
    color: #fff !important;
}

.news-events .news .news-title a {
    color: #fff !important;
}

.news-events .news-short-content {
    color: #fff !important;
}

.border-news {
    border-right: 1px solid #588fcb;
}

.alert-message {
    padding-top: 20px;
    padding-bottom: 20px;
}

.alert-text {
    font-size: 17px;
    color: white;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
}

.read-more {
    margin-top: 10px;
}

.read-more span {
    color: #29BEFF;
    font-weight: bolder;
}

.read-more a img {
    width: 40%;
    margin-left: 10px;
}

.close-button img {
    margin-top: 13px;
    width: 28%;
}

.sub-menu-row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.sub-menu-text {
    color: #6D5D85;
    font-size: 0.9rem;
}

.sub-menu-text.active {
    color: white;
}

.sub-menu-text.active::before {
    content: '\25bc';
    padding-left: 0.5em;
    font-size: 10px;
    color: #37C4BD;
    margin-right: 15px;
}

.page-template-fitness-center .sub-menu-text.active::before {
    content: '\25bc';
    padding-left: 0.5em;
    font-size: 10px;
    color: #37A564;
    margin-right: 15px;
}

.page-template-fitness-center .second-level-menu {
    border-top: 4px solid #37a564;
}

.page-template-fitness-center .second-level-container.active-container a::before {
    content: "";
    vertical-align: middle;
    margin: auto;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: calc(100% - 6px);
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: #37A564;
}

.second-level-menu {
    background-color: #D4D4D4;
    border-top: 8px solid #37C4BD;
}

.second-level-container {
    padding: 28px;
    text-align: center;
    border-right: 1px solid #868586;
}

.second-level-container a {
    font-size: 18px;
    color: #37265b;
    font-weight: 700;
}

.second-level-container.active-container {
    background-color: #37265b;
}

.second-level-container.active-container a {
    color: white;
}

.second-level-container.active-container a::before {
    content: "";
    vertical-align: middle;
    margin: auto;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: calc(100% - 6px);
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: #37C4BD;
}

.page-sidebar {
    background-color: #F0F0F0;
    padding-right: unset;
    padding-left: 20px;
}

.page-sidebar .wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title {
    margin: 0;
}

.staff-main-director {
    display: flex;
}

.staff-director {
    flex: 1 100%;
}

.director-name {
    margin-top: 9px;
    font-size: 23px;
    margin-bottom: 0;
    text-transform: capitalize;
    color: #4b1469;
    letter-spacing: .2px;
    font-weight: 600;
}

.owner-chat {
    color: #4abab8;
}

.page-template-early-childhood .owner-chat {
    color: #4abab8;
}

.director-position {
    margin-bottom: 2px;
    font-size: 11px;
    color: #37265b;
    font-weight: 600;
    padding-left: 24px;
}

.director-phone {
    color: #868586;
    font-size: 14px;
    line-height: 17px;
    display: block;
}

.brochure-div {
    margin-top: 22px;
}

.brochure-div p {
    font-size: 14px;
    margin-bottom: 0;
}

.brochure-div a {
    color: #868586;
}

.read-more-arrow {
    width: 9px;
    vertical-align: middle;
    margin-left: 2px;
}

.brochure-div .brochure-page {
    height: 127px !important;
    max-width: 100%;
    margin-bottom: 10px;
}


.director-phone-card {
    display: block;
    color: #868586;
    font-size: 11px;
    line-height: 13px;
}

.director-email {
    color: #868586;
    font-size: 14px;
    line-height: 17px;
    display: block;
}

.director-email i,
.director-phone i {
    color: #4abab8;
    font-size: 11px;
    margin-right: 8px;
}

.page-template-early-childhood .director-email i,
.page-template-early-childhood .director-phone i {
    color: #4abab8;
}

.sidebar-padding {
    padding-top: 30px;
}

.sidebar-main-heading {
    color: #4b1469;
    font-size: 13px;
    text-transform: inherit;
    letter-spacing: -0.1px;
    width: 100%;
    font-weight: 600;
    margin-top: 11px;
    margin-bottom: 0;
}

p.sidebar-main-heading:after {
    border-bottom: 2px solid #4b1469;
    content: '';
    display: block;
    padding-top: 1px;
}

.page-sidebar hr {
    margin-top: unset !important;
    margin-bottom: 1.2rem;
    border: 0;
    border-top: 3px solid #37265b;
}

.page-sidebar .card {
    background-color: unset !important;
    border-radius: unset !important;
    border: unset !important;
    padding: 1px 8px 0 0px;
    border-bottom: 1px solid #8e8e8e;
}

.page-sidebar .card-header {
    padding: unset !important;
    margin-bottom: unset !important;
    background-color: unset !important;
    border-bottom: 0 solid #C9C9C9 !important;
}

.page-sidebar .card {
    border-bottom: 1px solid #C9C9C9 !important;
}

.page-sidebar .card-header button {
    font-size: 18px;
    color: #868586;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.project-overview-left {
    padding-right: 50px;
}

.page-sidebar form#event_filters {
    display: none !important;
}

.page-sidebar .wpem-event-date {
    display: none !important;
}

.page-sidebar #event-listing-view .wpem-event-box-col {
    max-width: 100% !important;
    flex: 0 0 100% !important;
}

.page-sidebar .wpem-event-layout-wrapper {
    border: unset !important;
    background: unset !important;
}

.page-sidebar .wpem-event-banner {
    padding-right: 33px !important;
    background: unset !important;
}

.page-sidebar .wpem-event-banner-img {
    height: 170px !important;
}

.page-sidebar .wpem-heading-text {
    color: #37265b;
    font-size: 22px;
    font-weight: 700;
}

.page-sidebar .wpem-event-date-time {
    color: #868586
}

.page-sidebar .wpem-event-location {
    color: #868586
}

.page-sidebar .wpem-event-details {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
    padding-right: 10px !important;
}

.page-sidebar .wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location {
    margin: 0;
}

.page-sidebar .wpem-col {
    padding-right: unset !important;
}

.text-container {
    padding-right: 62px;
}

.sub-menu-row .text-container:last-child {
    padding-right: 0px;
}

.navbar .container {
    padding-right: 0px;
}

.purple-btn {
    padding: 5px 30px 5px 30px;
    background: #37265b;
    color: white;
    margin-left: 20px;
    border-radius: 30px;
    font-size: 18px;
}

.service-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.list-group-item {
    /* border: unset !important; */
    padding: unset !important;
    padding-bottom: 20px !important;
}

.service-col h5 {
    position: relative;
    color: #37265b;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.6px;
    margin-bottom: 0;
    line-height: 27px;
}

.service-col p {
    font-size: 13px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    line-height: 19px;
    color: #495057;
    font-weight: 400;
}

.arrow {
    border: solid #868586;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin-right: 12px;
    margin-bottom: 1px;
}

.arrow.arrow--small {
    padding: 3px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.faq-section .accordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f068";
    float: right;
    top: 10px;
    bottom: 0;
    position: relative;
    color: #37265b;
}

.faq-section .accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f067";
}

.faq-section .card {
    background-color: unset !important;
    border: unset !important;
    border-radius: unset !important;
}

.faq-section .card-header {
    background-color: unset !important;
    padding: 0.75rem 16px 0 0;
    border-bottom: 1px solid #DDEDFF !important;
}

.faq-section .card-body {
    /* padding: 1.25rem 0 !important; */
    font-size: 13.8px;
    line-height: 21px;
    margin-bottom: 4px;
}

.faq-section .card-title {
    color: #37265b;
    cursor: pointer;
    padding: 21px 0 19px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
    letter-spacing: -0.1px;
    font-weight: 800;
}

.news-events .news-more-link img {
    margin-left: 10px;
    width: 32%;
}

.news-events .event-button-container {
    width: 22%;
    padding-left: 8px;
    padding-top: 10px;
}

.news-events .event-button {
    color: white;
    padding: 8px 24px;
    font-size: 19px;
    border: 1px solid white;
    border-radius: 30px;
}

.jumbotron-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    border-radius: unset !important;
}

.jumbo-container .text-container {
    background: white;
    text-align: left;
    display: block;
    position: absolute;
    z-index: 1;
    top: 310px;
    margin-top: 60px;
    padding: 20px 0 20px 17px;
    width: 28%;
    box-shadow: 17px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px 7px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.5px;
}

.jumbo-container .text-container h1 {
    font-size: 40px;
    font-weight: 900;
    color: #54387F;
    margin-bottom: 0;
}

.message-text-container {
    padding: 30px 110px 60px;
    text-align: center;
}

.message-text-container h1 {
    color: #37265b;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.card-title-main {
    padding: 17px;
    text-align: center;
    background: #37265b;
    border-bottom: 8px solid #37C4BD;
    min-height: 96px;
    vertical-align: middle;
}

.card-title-main h1 {
    font-size: 20px;
    color: white;
    line-break: auto;
    overflow-wrap: break-word;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0 28px;
}

.non-feature-card .card-title-main {
    min-height: auto;
}

.non-feature-card .card-text {
    font-size: 13px !important;
    line-height: 17px;
    margin-bottom: 0;
    padding-bottom: 9px !important;
}

.right-body-non-feacture {
    padding: 20px;
}

.featured-card {
    padding-bottom: 90px;
}

.featured-card-group {
    padding-bottom: 90px;
}

.featured-card-group.non-feature-card {
    padding-bottom: 0px;
}

.featured-card .custom-card {
    flex-flow: row wrap !important;
}

.featured-card .custom-card .custom-card-body {
    flex: 1 20%;
}

.custom-card {
    border: unset !important;
    border-bottom: 8px solid #37C4BD !important;
    border-radius: unset !important;
    -webkit-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
}

.custom-card-body.left-body {
    background: #F0F0F0;
}

.custom-card-body img {
    border-radius: 0;
}

.custom-card-body .card-text {
    padding: 20px;
    font-size: 16px;
}

.custom-card-body.right-body {
    padding: 20px;
}

.featured-card-group .card-body {
    padding: 0px !important;
}

.featured-card-group .card-body .card-text {
    padding: 20px;
    font-size: 16px;
    color: #635465;
}

.featured-card-group .card-body img {
    border-radius: 0;
}

.featured-card-group .card-deck .card {
    border: unset !important;
    border-bottom: 8px solid #37C4BD !important;
    border-radius: unset !important;
    -webkit-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.17);
}

.page-parent .news-events {
    background-color: transparent !important;
}

.page-parent .news-events .grid-date-post {
    color: #635465 !important;
}

.page-parent .news-events .news .news-title a {
    color: #37265b !important;
}

.page-parent .news-events .news-short-content {
    color: #635465 !important;
    color: #635465 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-parent .news-events-heading {
    color: #A6A6A6 !important;
}

.page-parent.page-child .news-events {
    background-color: #37265b !important;
}

.page-parent.page-child .news-events .grid-date-post {
    color: #fff !important;
}

.page-parent.page-child .news-events .news .news-title a {
    color: #fff !important;
    font-weight: 700;
}

.page-parent .wpem-event-category {
    display: none;
}

.page-parent.page-child .news-events .news-short-content {
    color: #fff !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page-parent.page-child .innerpage-event .wpem-event-description p {
    color: #fff;
}

.page-parent.page-child .news-events-heading {
    color: #fff !important;
}

.page-parent .innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    /* content: ""; */
    color: #37265b !important;
}

.page-parent.page-child .innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    color: #fff !important;
}

.page-parent .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location {
    position: relative;
    padding-left: 16px;
}

.news-col-list .news-inner-wrap-view {
    border-bottom: unset !important;
}

.page-template-early-childhood .wpem-from-date .wpem-date {
    color: #37265b !important;
}

.page-template-early-childhood .wpem-from-date .wpem-month {
    color: #37265b !important;
}

.page-template-early-childhood .news-events .wpem-heading-text {
    color: #588fcb !important;
}

.page-template-early-childhood .news-events .wpem-event-location-text {
    color: #635465 !important;
}

.page-template-early-childhood .news-events .wpem-event-location::before {
    color: #635465 !important;
}

.gallery-row .col-md-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.gallery-row .white {
    padding: unset !important;
}

/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/

.site-footer {
    padding: 49px 0 0;
    background: #e2e3e7;
    color: #86868a;
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/grey-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.site-footer ul {
    list-style: none;
}

.site-footer p,
.site-footer li {
    color: #cdced0;
}

.site-footer .social-newsletter-area {
    background-color: #1c202e;
    padding: 55px 0 60px;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .social-newsletter-content {
        text-align: center;
    }
}

.site-footer .social-newsletter-area .social-newsletter-content>div:first-child {
    width: 320px;
    float: left;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .social-newsletter-content>div:first-child {
        width: 100%;
        float: none;
    }
}

.site-footer .social-newsletter-area .social-newsletter-content>div:nth-child(2) {
    width: calc(100% - 640px);
    float: left;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .social-newsletter-content>div:nth-child(2) {
        width: 100%;
        float: none;
        margin: 35px 0;
    }
}

.site-footer .social-newsletter-area .social-newsletter-content>div:last-child {
    width: 320px;
    float: right;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .social-newsletter-content>div:last-child {
        width: 100%;
        float: none;
    }
}

.site-footer .social-newsletter-area .social-area ul {
    overflow: hidden;
    padding-top: 28px;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .social-area ul {
        display: inline-block;
        padding-top: 0;
    }
}

.site-footer .social-newsletter-area .social-area ul li {
    float: left;
}

.site-footer .social-newsletter-area .social-area ul>li+li {
    margin-left: 10px;
}

.site-footer .social-newsletter-area .social-area ul a {
    background-color: #30364e;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-size: 0.875rem;
    color: #fff;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50px;
}

.site-footer .social-newsletter-area .social-area ul a:hover {
    background-color: #e95945;
}

.site-footer .social-newsletter-area .logo-area {
    text-align: center;
}

.site-footer .social-newsletter-area .logo-area img {
    display: inline-block;
    margin-left: -90px;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .logo-area img {
        margin-left: 0;
    }
}

.site-footer .social-newsletter-area .newsletter-area .inner {
    max-width: 400px;
}

@media (max-width: 991px) {
    .site-footer .social-newsletter-area .newsletter-area .inner {
        margin: 0 auto;
    }
}

.site-footer .social-newsletter-area .newsletter-area h3 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 400;
    color: #fff;
    margin: 0;
}

.site-footer .social-newsletter-area .newsletter-area form {
    margin-top: 25px;
    position: relative;
}

.site-footer .social-newsletter-area .newsletter-area form input {
    background-color: #30364e;
    height: 55px;
    color: #152b3a;
    padding: 6px 20px;
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.site-footer .social-newsletter-area .newsletter-area form button {
    background-color: #e95945;
    width: 55px;
    height: 55px;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    outline: 0;
    font-size: 20px;
    color: #fff;
}

.footer-first {
    padding-left: 11px;
}

p.footer-title {
    color: #588fcb;
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 9px;
}

ul.footer-links-ul {
    list-style: none;
    margin-left: -7px;
    padding-left: 0;
    margin-top: 4px;
    margin-bottom: 20px;
}

.site-footer a {
    color: #44394c;
    font-size: 15px;
    font-weight: 800;
}

.footer-links-ul li {
    position: relative;
    padding-left: 18px;
    line-height: 14px;
    margin-bottom: 12px;
}

    .footer-links-ul li:before {
        content: '';
        display: block;
        /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
        background: url(../../images/footer-arrow.png);
        position: absolute;
        width: 17px;
        height: 11px;
        left: 0;
        top: 3px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

.footer-links-ul li ul li {
    padding-left: 0;
    margin-top: -6px;
    margin-bottom: 13px;
}

.footer-links-ul li ul li:before {
    background: 0;
}

ul.footer-links-ul li ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}

.second-ul .footer-links-ul li {
    margin-bottom: 18px;
    padding-left: 15px;
}

.second-ul .footer-links-ul li ul li {
    padding-left: 0;
    margin-bottom: 13px;
}

.footer-loction {
    margin-top: 11px !important;
    margin-left: -9px !important;
    margin-bottom: 0;
}

.footer-loction li {
    margin-bottom: 13px;
}

p.footer-location-title {
    color: #635465;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 1px;
}

p.footer-location-dec {
    color: #635465;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px;
    letter-spacing: -0.2px;
}

.second-first .footer-links-ul li {
    line-height: 14px;
    margin-bottom: 15px;
}

.second-first p.footer-title {
    margin-bottom: 11px;
}

p.footer-location-phn {
    color: #635465;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
}

.site-footer .footer-location-phn a {
    font-weight: 500;
    color: #635465;
    letter-spacing: -0.4px;
}

.second-first:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/gray-line.png);*/
    background: url(../../images/gray-line.png);
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    padding-left: 0;
    margin-left: -51px;
}

.second-first {
    position: relative;
    padding-left: 37px;
}

ul.footer-links-ul.footer-location {
    margin-bottom: 0;
}

p.see-allfooter {
    margin-left: 2px;
    margin-top: -4px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.see-allfooter img {
    width: 14px
}

.footer-third {
    padding-left: 21px;
    height: 100%;
}

.footer-logo {
    margin-bottom: 20px;
}

.footer-logo img {
    width: 182px;
    display: block;
}

.footer-call-email {
    margin-top: 20px;
}

.footer-call-email a {
    color: #588fcb;
    font-size: 14px;
}

.footer-third:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/gray-line.png);*/
    background: url(../../images/gray-line.png);
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    padding-left: 0;
    margin-left: -43px;
}

.footer-third .pdr-0 {
    padding-right: 0;
}

.site-footer a.career-a {
    background: transparent;
    color: #37265b;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #37265b;
    position: absolute;
    bottom: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.site-footer a.doe-a {
    background: transparent;
    color: #37265b;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.site-footer a.footer-login {
    background: #37265b;
    color: #fff;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.mt-10 {
    margin-top: 10px;
}

.footer-login-second-btn {
    margin-left: 20px
}

.footer-social-icons {
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
}

ul.social-links {
    list-style: none;
    padding-left: 5px;
    margin-left: 0;
    text-align: left;
    margin-bottom: 0;
}

ul.social-links li {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 19px;
}

.social-links img {
    height: 14px;
}

.pdr-3 {
    padding-left: 3px !important;
}

.footer-legal li {
    display: inline-block;
    margin-right: 41px;
    margin-bottom: 0;
}

ul.footer-legal {
    list-style: none;
    padding-left: 0;
    margin-left: -9px;
    margin-bottom: 0;
}

.site-footer .footer-legal a {
    color: #a29fa3;
    font-weight: 300;
    font-size: 13px;
}

.site-rights p {
    font-size: 12.7px;
    color: #a29fa3;
}

.site-rights {
    text-align: right;
    font-size: 13px;
    margin-right: -10px;
}

.page {
    margin: 0;
    padding-bottom: 0;
}

.site-footer .goto-top {
    position: fixed;
    bottom: -50px;
    right: 14.5%;
    width: 43px;
    height: 43px;
    background: transparent;
    font-size: 22px;
    line-height: 40px;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    opacity: 1;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: 24.1% !important;
    border: 0;
    padding: 0;
    z-index: 9;
}

button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

li>ul,
li>ol {
    margin-bottom: 0;
    margin-left: 1.5em;
    margin-top: 12px;
}

.site-footer p {
    line-height: 1.618;
}

#menu-item-452 a,
#menu-item-453 a {
    color: #588fcb;
}


@media (max-width: 991px) {
    .site-footer .upper-footer {
        padding: 90px 0 20px;
    }
}

@media (max-width: 767px) {
    .site-footer .upper-footer {
        padding: 80px 0 10px;
    }
}

@media (max-width: 991px) {
    .site-footer .upper-footer .col {
        min-height: 235px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .site-footer .upper-footer .col {
        min-height: auto;
        margin-bottom: 60px;
    }
}

.site-footer .widget-title {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .site-footer .widget-title {
        margin-bottom: 20px;
    }
}

.site-footer .widget-title h3 {
    font-size: 1.45rem;
    font-weight: 700;
    color: #588fcb;
    margin: 0;
    padding-bottom: 0.4em;
    text-transform: capitalize;
    position: relative;
}

@media (max-width: 991px) {
    .site-footer .widget-title h3 {
        padding-bottom: 0;
    }
}

.site-footer .about-widget p {
    margin-bottom: 0.8em;
    line-height: 1.9em;
}

.site-footer .about-widget p:last-child {
    margin-bottom: 0;
}

.site-footer .link-widget {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .site-footer .link-widget {
        padding-left: 20px;
    }
}

@media (max-width: 1199px) {
    .site-footer .link-widget {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {
    .site-footer .link-widget {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .site-footer .link-widget {
        max-width: 350px;
    }
}

.site-footer .link-widget ul {
    width: 50%;
    float: left;
}

.site-footer .link-widget ul li {
    position: relative;
}

.site-footer .link-widget ul a {
    color: #cdced0;
    font-weight: 500;
}

.site-footer .link-widget ul a:hover,
.site-footer .link-widget ul li:hover:before {
    text-decoration: underline;
}

.site-footer .link-widget ul>li+li {
    margin-top: 15px;
}

.site-footer .line-widget-2 ul {
    width: 100%;
    float: none;
}

@media screen and (min-width: 1200px) {
    .site-footer .contact-widget {
        padding-left: 25px;
    }
}

.site-footer .contact-widget ul {
    margin-top: 25px;
}

.site-footer .contact-widget ul li {
    position: relative;
}

.site-footer .contact-widget ul>li+li {
    margin-top: 15px;
}

.site-footer .contact-widget li span {
    font-weight: 500;
}

.site-footer .lower-footer {
    text-align: center;
    position: relative;
}

.site-footer .lower-footer .row {
    padding: 20px 0;
    position: relative;
}

.site-footer .lower-footer .row .separator {
    background: rgba(255, 255, 255, 0.1);
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    left: 15px;
    top: 0;
}

.site-footer .lower-footer .copyright {
    display: inline-block;
    float: left;
    font-size: 14px;
    font-size: 0.875rem;
    margin: 0;
}

@media (max-width: 991px) {
    .site-footer .lower-footer .copyright {
        float: none;
        display: block;
        margin: 0 0 20px;
    }
}

.site-footer .lower-footer .copyright a {
    color: #cdced0;
    text-decoration: underline;
}

.site-footer .lower-footer .extra-link {
    display: inline-block;
    float: right;
    overflow: hidden;
}

@media (max-width: 991px) {
    .site-footer .lower-footer .extra-link {
        float: none;
        display: block;
        text-align: center;
    }
}

.site-footer .lower-footer .extra-link ul {
    overflow: hidden;
    list-style: none;
}

@media (max-width: 991px) {
    .site-footer .lower-footer .extra-link ul {
        display: inline-block;
    }
}

.site-footer .lower-footer .extra-link ul li {
    float: left;
}

.site-footer .lower-footer .extra-link ul>li+li {
    margin-left: 35px;
    position: relative;
}

@media (max-width: 767px) {
    .site-footer .lower-footer .extra-link ul>li+li {
        margin-left: 15px;
    }
}

.site-footer .lower-footer .extra-link ul>li+li:before {
    content: "";
    background: #10202f;
    width: 15px;
    height: 1px;
    position: absolute;
    left: -25px;
    top: 11px;
}

@media (max-width: 767px) {
    .site-footer .lower-footer .extra-link ul>li+li:before {
        display: none;
    }
}

.site-footer .lower-footer .extra-link ul a {
    font-size: 14px;
    font-size: 0.875rem;
    color: #cdced0;
}

.site-footer .lower-footer .extra-link ul a:hover {
    text-decoration: underline;
}

.fixed .sticky-header {
    position: fixed;
    top: 0;
    opacity: 1;
}

.sticky-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: -300px;
    z-index: 9999;
    opacity: 0;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
}

.sticky-on {
    opacity: 1;
    top: 0;
}

.header-style-1 .sticky-header,
.header-style-2 .sticky-header,
.header-style-3 .sticky-header {
    background-color: #002b3c;
}

@media screen and (min-width: 992px) {

    .header-style-2 .sticky-header,
    .header-style-3 .sticky-header {
        background: -webkit-linear-gradient(left, #e95945 50%, #fff 50.1%);
        background: -moz-linear-gradient(left, #e95945 50%, #fff 50.1%);
        background: -o-linear-gradient(left, #e95945 50%, #fff 50.1%);
        background: -ms-linear-gradient(left, #e95945 50%, #fff 50.1%);
        background: -webkit-gradient(linear, left top, right top, color-stop(50%, #e95945), color-stop(50.1%, #fff));
        background: linear-gradient(left, #e95945 50%, #fff 50.1%);
        border-bottom: 1px solid #e3e3e3;
    }

    .header-style-2 .sticky-header .container-fluid,
    .header-style-3 .sticky-header .container-fluid {
        border-top: 0;
    }
}

.header-style-3 .sticky-header {
    background: #fff;
}

.page-logo {
    width: 40%;
    margin-bottom: 20px;
}

.card-header {
    padding: 19px 0 0 16px;
}

.staff-card-title {
    background-color: transparent;
    cursor: pointer;
    padding: 16px 0 0px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
    letter-spacing: 0.7px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}

.card-header.collapsed .staff-card-title {
    color: #868586;
    margin-bottom: 12px;
}

.card-header .staff-card-title {
    color: #4b1469;
    margin-bottom: 12px;
}

.staff-card-title img {
    width: 6%;
    margin-left: 6px;
}

.director-position-card {
    font-size: 16px;
    color: #37265b;
    letter-spacing: -0.2px;
    line-height: 21px;
    margin-bottom: 0px;
}

.director-email-card {
    display: block;
    color: #868586;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
}

.director-email-card img {
    margin-right: 12px;
}

.page-template-early-childhood .director-email-card i {
    color: #4abab8;
    font-size: 11px;
    margin-right: 3px;
}

.director-email-card i {
    color: #4abab8;
    font-size: 11px;
    margin-right: 3px;
}

.director-phone-card {
    display: block;
    color: #868586;
    font-size: 11px;
    line-height: 13px;
}

.director-phone-card img {
    margin-right: 12px;
}

.card-header.collapsed h5:after {
    content: '';
    font-size: 13px;
    color: #37265b;
    float: right;
    margin-left: 5px;
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/yvy-website-v1h-xd-4.png);
    width: 9px;
    height: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 3px;
}

.card-header h5:after {
    content: "";
    color: #37265b;
    margin-top: 3px;
    float: right;
    margin-left: 5px;
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/yvy-website-v1h-xd-3.png);
    width: 9px;
    height: 8px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.news-events .wpem-event-date {
    border-right: 0px solid #588fcb;
}

.video-section .section-image {
    width: 100%;
}

.services-image {
    width: 100%;
}

/*hiral css*/
.navbar {
    box-shadow: 1px 2px 15px 4px rgba(0, 0, 0, 0.1), 2px 0px 2px -3px rgba(0, 0, 0, 0.05);
    z-index: 9;
    position: relative;
    padding: 20px 0 24px;
    background: #fff;
}

.navbar-light .navbar-nav .nav-link {
    color: #37265b;
}

#menu-menu-1 li {
    margin-left: 27px;
}

.navbar-nav {
    padding-top: 4px;
}

.navbar .container {
    padding: 0 49px;
}

.navbar .purple-btn {
    background: #37265b;
    color: #fff;
    padding: 7px 23px;
    border-radius: 20px;
    margin-left: 28px;
    font-size: 13px;
    font-weight: 700;
}

.owl-carousel .item .carousel-content {
    position: absolute;
    bottom: 28.8%;
    left: 0;
    opacity: 1;
    margin: 0;
    display: block;
    padding: 25px 71px 15px 13px;
    width: 48%;
    box-shadow: 17px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px 7px rgba(0, 0, 0, 0.05);
    border-left: 0px solid #ccc;
    background: #54387f7a;
    color: #fff;
    padding-left: 15%;
}

.owl-carousel .item .carousel-content h4 {
    font-size: 34px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
}

.owl-carousel .item .carousel-content p {
    font-size: 17px;
}

.owl-theme .owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.owl-theme .owl-dots .owl-dot span {
    width: 9px;
    height: 9px;
    margin: 0 6px;
    border: 1.5px solid #fff;
    background: #fff;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #3a7cc4 !important;
}

.pd-0 {
    padding: 0;
}

.alert-banner {
    background-color: #3a7cc4;
    margin: 0;
    border: 0;
    border-radius: 0;
    color: white;
    font-size: 17px;
    letter-spacing: -0.5px;
    font-weight: 600;
    z-index: 1;
    padding: 25px 0;
}


.read-more-sec {
    color: #29BEFF;
    font-weight: 800;
    font-size: 16px;
    margin-left: 45px;
}

.read-more-sec:hover {
    text-decoration: none;
}

.closebtn {
    color: rgba(255, 255, 255, .3);
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 4px;
}

@media (max-width: 768px){
    .alert-banner a{
        display: block;
        margin-top: 8px;
    }
    .alert-banner a .read-more-sec{
        margin-left: 0;
    }

    .alert.alert-banner{
        padding: 20px 0 !important;
    }
}


.covid-icon {
    width: 34px;
    vertical-align: middle;
    margin-right: 32px;
}


.yeled-text-sec {
    background: #37265b;
    color: #fff;
    padding: 43px 0 58px;
    text-align: center;
}

.yeled-text-sec h1 {
    font-size: 22px;
    font-weight: 800;
    line-height: 35px;
    color: #fff;
    letter-spacing: 0.5px;
    padding: 0 130px;
    margin-bottom: 0;
}

.spacer-inner-52 {
    height: 82px;
}

.spacer-inner-50 {
    height: 50px;
}

.service-sec .heading-title h2 {
    color: #A6A7A7;
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 29px;
    margin-bottom: 0;
}

.service-sec .heading-title h2:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 17px;
}

.card-deck .card {
    border: 0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0;
}

.card-body {
    padding: 0;
    padding-bottom: 10px;
}

h5.card-title {
    background: #37265b;
    color: #fff;
    text-align: center;
    padding: 14px 10px 16px;
    font-size: 21px;
    line-height: 26px;
    font-weight: bold;
    letter-spacing: -0.3px;
    word-spacing: 5px;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
}

.card-deck .card:first-child h5.card-title,
.card-deck .card:first-child {
    border-bottom: 8px solid #4abab8;
}

.card-deck .card:nth-child(2) h5.card-title,
.card-deck .card:nth-child(2) {
    border-bottom: 8px solid #e23d3b;
}

.card-deck .card:nth-child(3) h5.card-title,
.card-deck .card:nth-child(3) {
    border-bottom: 8px solid #f5865c;
}

.card-deck .card:nth-child(4) h5.card-title,
.card-deck .card:nth-child(4) {
    border-bottom: 8px solid #b7805c;
}

.card-deck .card:last-child h5.card-title,
.card-deck .card:last-child {
    border-bottom: 8px solid #37a564;
}

.card-body .list-group-flush .list-group-item a {
    color: #588fcb;
}

.card-body .list-group-flush.card_option_2_ul .list-group-item {
    font-weight: 600;
}

.card_list_2_label {
    color: #588fcb;
    font-weight: 800;
    margin-bottom: 0;
}

.card-body .list-group-flush {
    padding: 18px;
}

.pl-17 {
    padding-left: 17px;
}

.page-child .rbox-job-fullpage {
    color: #635465;
}

.page-child .rbox-opening-detail .rbox-opening-position-info {
    display: none;
}

.rbox-widget button.rbox-btn {
    background: #37265b;
    color: #fff;
    padding: 6px 23px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
    transition: .3s;
}

.rbox-widget button.rbox-btn:hover {
    background: #37265b;
    color: #fff;
}

.home .rbox-widget button.rbox-btn {
    background: #fff;
    color: #635465;
}

.home .rbox-widget button.rbox-btn:hover {
    background: #fff;
    color: #635465;
}

.modal-body .rbox-opening-detail.rbox-widget {
    visibility: visible !important;
}

a:hover {
    text-decoration: none;
}

.hero-slider {
    margin-top: -9.1%;
    padding: 0
}

.hero-slider .owl-carousel .owl-item img {
    height: 587px;
    object-fit: cover;
}

.scroll-down-btn {
    margin-top: -90px;
    z-index: 999;
    position: relative;
}

.scroll-down-btn img {
    width: 40px;
    margin: auto;
    display: block;
}

.alert.alert-banner.innerpage-topbar {
    margin-top: 0;
}

.group-title-list li {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 12.79px;
    margin-bottom: 0;
    padding-right: 21px;
    font-weight: 500;
    letter-spacing: -0.1px;
    padding-left: 38px;
}

.group-title-list li:last-child {
    margin-right: 0;
}

.group-title-list li:first-child {
    margin-left: 0;
}

ul.group-title-list {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
}

.group-title {
    margin-top: -1px;
}

.group-title-list li.active:before {
    content: '';
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/early-child-down.png);
    display: block;
    width: 10px;
    height: 8px;
    position: absolute;
    background-position: center;
    background-size: contain;
    margin-left: -23px;
    top: 5px;
}

.page-template-special-education-main .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/special-education-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-special-education-main .card-title-main {
    border-bottom: 8px solid #e23d3b;
}

.page-template-special-education-main .custom-card {
    border-bottom: 8px solid #e23d3b !important;
}

/* .page-template-special-education-main .non-feature-card .card-deck .card:last-child .card-body .list-group-flush .list-group-item:after {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/a1-1.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
} */

.page-template-student-services-main .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/student-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-student-services-main .card-title-main {
    border-bottom: 8px solid #f5865c;
}

.page-template-student-services-main .custom-card {
    border-bottom: 8px solid #f5865c !important;
}

/* .page-template-student-services-main .non-feature-card .card-deck .card:last-child .card-body .list-group-flush .list-group-item:after {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/a2-1.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat; 
}
*/

.page-template-community-services-main .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/broun-group-1.png);
    background-position: center;
    background-size: contain;
}

.page-template-community-services-main .card-title-main {
    border-bottom: 8px solid #b7805c;
}

.page-template-community-services-main .custom-card {
    border-bottom: 8px solid #b7805c !important;
}

.page-template-community-services-main .non-feature-card .card-deck .card:last-child .card-body .list-group-flush .list-group-item:after {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/a3.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

.page-template-fitness-center-main .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/fitness-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-fitness-center-main .card-title-main {
    border-bottom: 8px solid #37a564;
}

.page-template-fitness-center-main .custom-card {
    border-bottom: 8px solid #37a564 !important;
}

.page-template-fitness-center-main .non-feature-card .card-deck .card:last-child .card-body .list-group-flush .list-group-item:after {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/a4-1.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

.page-template-special-education-main .news-events-heading:after {
    border-bottom: 2px solid #e23d3b;
}

.page-template-student-services-main .news-events-heading:after {
    border-bottom: 2px solid #f5865c;
}

.page-template-community-services-main .news-events-heading:after {
    border-bottom: 2px solid #b7805c;
}

.page-template-fitness-center-main .news-events-heading:after {
    border-bottom: 2px solid #37a564;
}


video {
    width: 100%;
}

.group-title-list li.active {
    position: relative;
}

.group-title-list li:first-child {
    margin-left: 0;
    padding-left: 35px;
}

.group-title-list li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.group-title-list li.active a {
    color: #fff;
}

.group-title-list li a {
    color: #6d5d85;
}

.group-topic-menu {
    background: #d3d3d3;
    border-top: 4px solid #4abab8;
    margin-top: -1px;
}

ul.group-topic-list li {
    display: inline-block;
    padding-left: 36px;
    padding-right: 40px;
    width: 19.1%;
    text-align: center;
    border-left: 1px solid #9a9a9a;
    margin-bottom: 0;
    padding-top: 22px;
    padding-bottom: 16px;
    line-height: 21px;
}

ul.group-topic-list {
    list-style: none;
    padding-left: 31px;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 0;
}

ul.group-topic-list li:nth-child(2) {
    width: 16.4%;
    padding-left: 22px;
    padding-right: 10px;
}

ul.group-topic-list li:nth-child(3) {
    width: 22.3%;
    padding-left: 30px;
    padding-right: 30px;
}

ul.group-topic-list li:nth-child(4) {
    width: 15.8%;
    padding-left: 41px;
    padding-right: 40px;
}

ul.group-topic-list li a {
    font-size: 16px;
    color: #4b1469;
    font-weight: 700;
    line-height: 13px;
}

ul.group-topic-list li:last-child {
    text-align: center;
    padding-left: 42px;
    padding-right: 40px;
    width: 23%;
}

ul.group-topic-list li a {
    font-size: 15px;
    color: #4b1469;
    font-weight: 600;
    line-height: 13px;
    font-family: 'Montserrat', sans-serif;
}

ul.group-topic-list li:nth-child(3) {
    width: 22.4%;
    padding-left: 30px;
    padding-right: 30px;
}

ul.group-topic-list li.active a {
    color: #fff;
}

ul.group-topic-list li.active {
    background: #4b1469;
    position: relative;
}

ul.group-topic-list li:first-child {
    padding-left: 32px;
    padding-right: 30px;
    border-left: 0;
}

ul.group-topic-list li.active:before {
    top: -3px;
    content: '';
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/early-child-caret.png);
    width: 18px;
    height: 17px;
    display: block;
    position: absolute;
    margin: 0 auto;
    text-align: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 46%;
}

h1.main-heading {
    color: #635465;
    font-family: 'Dosis', sans-serif;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 0;
    line-height: 58px;
}

p.group-name {
    color: #4abab8;
    font-size: 12.8px;
    margin-bottom: 7px;
    padding-left: 4px;
}

.page-template-early-childhood p.group-name {
    color: #4abab8;
}

.page-template-project-reach .second-level-menu {
    border-top: 4px solid #4abab8;
}

.page-template-early-childhood .second-level-sub-menu {
    border-bottom: 4px solid #4abab8;
}

.page-template-special-education .second-level-menu {
    border-top: 4px solid #e23d3b;
}


.page-template-student-services .second-level-menu {
    border-top: 4px solid #f5865c;
}

.group-detail {
    color: #868586;
    font-size: 15px;
    padding-left: 2px;
    font-weight: 800;
    padding-right: 40px;
    letter-spacing: .2px;
    margin-bottom: 20px;
}

a.btn-jump-section {
    margin-bottom: 0;
    padding: 6px 21px 6px 19px;
    background: #4b1469;
    font-size: 14px;
    margin-left: 2px;
    color: #fff;
    font-weight: 500;
    float: right;
}

.page-child .grid-date-post {
    line-height: 12px;
    margin-left: -2px;
}

.page-template .grid-date-post {
    font-weight: 800;
    font-size: 10px;
    margin-bottom: 0;
}

.news-content .post-content-text {
    width: 97%;
}

.page-child .news-content .post-content-text {
    width: 107%;
}

.page-template .news .news-title {
    color: #37265b;
    font-size: 14px;
    padding-right: 20px;
    line-height: 16px;
    margin-bottom: 4px;
    width: 87%;
}

.page-template .news .news-title a {
    font-size: 23px;
    line-height: 25px;
    color: #37265b;
    text-decoration: none !important;
    letter-spacing: -0.9px;
    font-weight: 800;
}

.page-child .news .news-title a {
    margin-left: -3px;
    letter-spacing: -1px;
    line-height: 24px;
}

.page-child .news .news-title {
    padding-right: 0px;
    padding-top: 6px;
}

.news-content-excerpt {
    position: relative;
}

.page-child .news-content-excerpt {
    margin-top: 12px;
    margin-left: -2px;
    padding-right: 41px;
}

.news-short-content {
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 18px;
    margin-bottom: 10px;
}

.page-child .news-short-content {
    font-size: 13px;
    letter-spacing: -0.3px;
    line-height: 17px;
}

.news-content-excerpt .news-more-link {
    color: #29BEFF;
    font-weight: 800;
    font-size: 13px;
    background: transparent;
    padding: 0;
    margin-left: 0;
    position: relative !important;
    left: 0 !important;
    text-transform: capitalize;
}

.page-child .news-content-excerpt .news-more-link,
.page-parent .news-content-excerpt .news-more-link {
    left: 47.6%;
    top: 56%;
    position: absolute;
}

.news-content-excerpt a.news-more-link:after {
    content: '';
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/readmore-arrow-1.png);
    display: block;
    width: 48px;
    height: 13px;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 45%;
    left: 110%;
    right: 0;
    transform: translateY(-50%);
    position: absolute;
    background-repeat: no-repeat;
}

.home .news-content-excerpt a.news-more-link:after {
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
    background: url(../../images/footer-arrow.png);
    display: block;
    width: 8px;
    height: 13px;
    background-position: center;
    background-size: contain;
}

.job-sidebar {
    padding: 2px 13px 15px 1px;
}

.job-title {
    font-size: 12px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    margin-top: 18px;
}

.page-parent.page-child a.rbox-opening-li-title {
    margin-top: 19px;
    font-size: 20px;
    color: #37265b;
    margin-bottom: 0;
    line-height: 28px;
}

.page-parent.page-child .rbox-opening-li {
    padding: 0;
    margin-bottom: 13px;
    border-bottom: 1px solid #A7C7EA;
    border-top: 0px dotted rgba(127, 127, 127, 0.2);
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
}

.page-parent.page-child .rbox-opening-list {
    margin-left: 0;
}

.rbox-jobs-group+.rbox-jobs-group {
    margin-top: 0 !important;
}

.rbox-opening-li {
    padding: 0 !important;
    margin-bottom: 13px;
    border-bottom: 1px solid #29beff !important;
    border-top: 0px dotted rgba(127, 127, 127, 0.2) !important;
    position: relative;
    padding-top: 0;
}

.rbox-opening-li a {
    text-decoration: none;
    color: #37265b;
    font-weight: 600;
}

.page-parent.page-child .rbox-job-shortdesc,
.page-child .rbox-job-shortdesc {
    font-size: 11px;
    letter-spacing: 0.1px;
    color: #635465;
}

.page-parent.page-child .rbox-opening-position-info,
.page-child .rbox-opening-position-info {
    display: block;
    font-size: 13px;
    margin-bottom: 0;
    color: #29beff;
    font-weight: 700;
    font-size: 0;
    margin-top: 1px;
    position: relative;
    page-break-after: always;
    left: 0;
}

.page-parent.page-child .rbox-opening-position-type,
.page-child .rbox-opening-position-type {
    font-size: 0;
    border: 0;
    padding: 0;
    display: none;
}

.page-parent.page-child .rbox-opening-position-info:before,
.page-child .rbox-opening-position-info:before {
    content: '';
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
    background: url(../../images/footer-arrow.png);
    width: 17px;
    height: 12px;
    background-position: center;
    background-size: contain;
    font-size: 13px;
    position: absolute;
    top: 5px;
    left: -3px;
    background-repeat: no-repeat;
}

.page-parent.page-child .rbox-opening-li:after {
    display: none;
}

.page-parent.page-child .rbox-opening-position-info:after,
.page-child .rbox-opening-position-info:after {
    font-size: 13px;
    content: 'Apply';
    padding-left: 14px;
    font-weight: 700;
    line-height: 24px;
    display: block;
}

p.sell-all-btn {
    text-align: right;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

p.sell-all-btn .arrow {
    font-size: 14px;
}

.sell-all-btn a {
    color: #3FCCCC !important;
}

.sell-all-btn img {
    width: 8px;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 2px;
}

a.btn-jump-section:hover {
    color: #fff;
}

.additional-link-list {
    padding-top: 13px;
}

.additional-links {
    position: relative;
    font-size: 14px;
    color: #868586;
    margin-bottom: 5px;
    line-height: 19px;
}

.additional-links a {
    position: relative;
    font-size: 14px;
    color: #868586;
    margin-bottom: 5px;
    line-height: 19px;
}

.page-child .rbox-opening-list {
    height: 276px;
    overflow-y: scroll !important;
}

.rbox-widget h3 {
    display: none;
}

.families-sec {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 0px 8px 30px;
    background: #fff;
}

.families-sec p {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 13px;
}

.parents-teacher {
    background: #fff;
}

.mlr-8 {
    margin: 0 8px;
}

.mb-10 {
    margin-bottom: 10px;
}

.families-sec img {
    object-fit: cover;
    min-height: 110.3%;
    margin-top: -30px;
    object-position: center;
}

.parents-login-btn {
    position: absolute;
    bottom: 0;
}

.spacer-inner-112 {
    height: 112px;
}

.parents-teacher-title {
    background: #588fcb;
    color: #fff;
    font-size: 20px;
    padding-left: 30px;
    padding: 12px 0 11px 30px;
}

.parents-teacher-title h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding-left: 12px;
}

.parents-teacher .row .col-sm-6:first-child {
    padding-left: 0;
}

.parents-teacher .row .col-sm-6:last-child {
    padding-right: 0;
}

.parents-teacher-des {
    padding: 16px 17px 16px 31px;
}

.families-sec p {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 13px;
}

.parents-teacher-ul {
    margin-top: 10px;
}

.parents-teacher-ul>li {
    position: relative;
    border-bottom: 0.5px solid #DDEDFF;
    padding: 7px 0;
    padding-left: 26px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 1px;
}

.parents-teacher-ul>li a {
    color: #588fcb;
}

    .parents-teacher-ul > li:after {
        content: '';
        /*background-image: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
        background-image: url(../../images/footer-arrow.png);
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-position: top;
        background-size: contain;
    }

.blank-purple-section {
    background: #37265b;
    height: 195px;
    margin-top: -150px;
}

.mb-10 {
    margin-bottom: 10px;
}

.btn-primary {
    background: #37265b;
    color: #fff;
    padding: 4px 23px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
}

.btn-primary:hover {
    color: #fff;
    background-color: #37265b;
    border-color: #37265b;
}

/* added for btn-secondary */


.btn-secondary {
    padding: 4px 23px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
    font-size:18px;
    border-width:2px;
    background-origin: border-box;
}

img {
    height: auto;
    max-width: 100%;
}

.parents-teacher-ul {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
}

.count-secs {
    background: #37265b;
    color: #fff;
    padding: 61px 45px;
}

.count-sec {
    text-align: center;
}

.counts {
    width: 167px;
    height: 167px;
    border-radius: 50%;
    font-size: 43px;
    color: #fff;
    line-height: 167px;
    text-align: center;
    border: 2px solid #5c0086;
    margin: 0 auto;
    font-weight: bold;
}

.count-sec p {
    margin-top: 16px;
    font-weight: 500;
    letter-spacing: -0.4px;
    margin-bottom: 0;
}

.heading-title h2 {
    color: #A6A7A7;
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 29px;
    margin-bottom: 0;
}

.heading-title h2:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 17px;
}

.home .news-col-2 .grid-news-thumb {
    height: 168px;
}

.home .news-col-2 {
    width: 100%;
}

.home .news-thumb {
    width: 25%;
    float: left;
}

.home .news-content {
    float: left;
    width: 75%;
    padding-left: 15px;
    clear: none;
}

#home-news-list .type-news:nth-child(4n) {
    display: none;
}

#home-news-list .type-news {
    display: none;
}

#home-news-list .type-news:nth-child(-n+3) {
    display: block;
}

.page-parent .type-news {
    display: none;
}

.page-parent .type-news:nth-child(-n+4) {
    display: block;
}

.page-parent #event-listing-view .wpem-event-box-col {
    display: none;
}

.page-parent #event-listing-view .wpem-event-box-col:nth-child(-n+3) {
    display: list-item;
}

.home .type-event_listing a.wpem-event-action-url.event-style-color {
    padding: 0;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation {
    width: 75%;
}

.spacer-inner-40 {
    height: 40px;
}

.home #event-listing-view {
    /*max-height: 465px;*/
    overflow: hidden;
}

.wpem-event-listings-header {
    display: none;
}

.gmap_canvas,
.mapouter {
    width: 100% !important;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner .wpem-event-banner-img {
    height: 168px;
    width: 103px;
}

#event_filters {
    display: none;
}

.home .wpem-event-banner,
.wpem-event-date-time {
    display: block;
}

.wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    border-left: 0;
    background: transparent;
}

.wpem-event-listings .wpem-event-layout-wrapper {
    border-bottom: 0px solid #e4e4e4;
    border-right: 0px solid #e4e4e4;
    background: transparent;
}

.wpem-event-listings .wpem-event-layout-wrapper:hover .wpem-event-action-url {
    border-width: 0;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-month {
    color: #635465;
    font-weight: 700;
    font-size: 10px;
    margin-top: -3px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-date {
    color: #37265b;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    padding: 12px 0px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-date {
    color: #37265b;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-month {
    color: #635465;
    font-weight: 700;
    font-size: 10px;
    margin-top: -3px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
    padding-left: 0;
    color: #588fcb;
    margin-top: 0;
    position: relative;
}

.home .type-event_listing .wpem-event-details a.wpem-event-action-url.event-style-color {
    width: 100%;
    padding: 0 0 12px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title {
    margin-bottom: 0;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #588fcb;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 10px;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location {
    margin: 0 !important;
    color: #635465;
    font-weight: 600;
    margin-top: -5px;
    letter-spacing: -0.4px;
}

.home .wpem-event-listing-list-view .wpem-event-box-col {
    margin-bottom: 0;
    min-height: 155px;
}

p.sell-all-btn.event-see-all {
    margin-right: 0%;
    margin-top: -14px;
}

#home-news-list:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/Trazado-161.png);*/
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    padding-left: 0;
    margin-left: 8px;
    right: 0;
}

.home .wpem-event-date {
    display: none;
}

.wpem-event-listings .wpem-event-layout-wrapper {
    border: 0;
    width: 100%;
    height: 100%;
    background: transparent;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    display: block;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details:before {
    /*content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/Trazado-62.png);
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    padding-left: 0;
    margin-left: -17px;
    top: 9px;*/
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location {
    margin: 0 !important;
    color: #635465;
    font-weight: 600;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    color: #37265b;
}

.home .wpem-event-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.home .wpem-event-description {
    color: #635465;
    font-weight: 400;
    font-size: 13px;
}

.home .wpem-event-description p {
    font-size: 13px;
    letter-spacing: 0.3px;
    margin-top: 2px;
    line-height: 18px;
    margin-bottom: 0;
}

.home .event-register-btn {
    color: #37265b !important;
    border: 1px solid #37265b;
    width: max-content !important;
    padding: 1px 17px !important;
    border-radius: 20px;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Open Sans';
    margin-top: -3px;
}

.home .wpem-event-listing-list-view .wpem-event-box-col {
    margin-bottom: 0;
    min-height: 155px;
    margin-bottom: 50px;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-box-col {
    padding-bottom: 30px;
}

.wpem-event-listings .wpem-event-layout-wrapper {
    border-bottom: 0px solid #e4e4e4 !important;
    border-right: 0px solid #e4e4e4 !important;
}

.home .event-register-btn:hover {
    border: 1px solid #37265b;
    width: max-content !important;
    padding: 1px 17px !important;
    color: #37265b;
}

.wpem-event-listings .wpem-event-layout-wrapper:hover .wpem-event-action-url {
    border-width: 1px !important;
}

ul.location-list-ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 4px;
    margin-top: 9px;
    margin-left: 0;
    height: 393px;
    overflow-x: hidden;
}

.office-name {
    color: #37265b;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
}

.location-list-ul li.active:before {
    content: '';
    display: block;
    width: 7px;
    height: 59px;
    background: #588fcb;
    position: absolute;
}

.location-list-ul li.active div {
    padding-left: 15px;
}

p.office-address {
    font-size: 13px;
    letter-spacing: -0.2px;
    margin-bottom: 0;
    line-height: 18px;
    font-weight: 600;
}

.office-phone {
    font-size: 13px;
    margin-bottom: 0;
}

.office-phone a {
    color: #635465;
    font-weight: 600;
}

.location-list-ul li {
    border-bottom: 1px solid #ccc;
    width: 87%;
    position: relative;
    cursor: pointer;
}

.location-list-ul .list-content {
    padding: 20px 12px;
}

.location-list-ul .list-content:hover {
    background-color: #eeeeee;
}

.location-list-ul li:last-child {
    border-bottom: 0 solid #ccc;
}

.career-sec {
    background: #37265b;
    position: relative;
    padding-left: 217px;
    padding-right: 187px;
    margin-top: -5px;
    padding-top: 30px;
    padding-bottom: 30px;
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/purple-bg.png);*/
    background: url(../../images/purple-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.career-sec .heading-title h2 {
    font-size: 40px;
    text-transform: capitalize;
    color: #fff !important;
}

.spacer-inner-20 {
    height: 20px;
}

.rbox-widget .rbox-jobs-group h3 {
    color: #A6A7A7;
    text-transform: uppercase;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 29px;
    margin-bottom: 15px;
}

.rbox-widget .rbox-jobs-group h3:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 3px;
}

.rbox-opening-li a {
    text-decoration: none;
    color: #37265b;
    font-weight: 600;
}

.home .rbox-widget .rbox-jobs-group h3,
.page-parent .rbox-widget .rbox-jobs-group h3 {
    display: none;
}

.home .rbox-opening-list,
.page-parent .rbox-opening-list {
    padding-left: 0;
    margin-left: -7px;
    margin-top: -5px;
    height: 340px;
    overflow: scroll !important;
    margin-bottom: 0;
    overflow-x: hidden !important;
}

.home .rbox-opening-li,
.page-parent .rbox-opening-li {
    padding: 0;
    width: 96%;
    margin-bottom: 13px;
    padding-left: 28px;
    border-bottom: 0px dotted rgba(127, 127, 127, 0.2);
    border-top: 0px dotted rgba(127, 127, 127, 0.2);
    position: relative;
    padding-top: 0;
}

.home a.rbox-opening-li-title,
.page-parent a.rbox-opening-li-title {
    color: #fff;
    font-size: 23px;
    letter-spacing: -0.3px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 29px;
    position: relative;
}

.home a.rbox-opening-li-title:before,
.page-parent a.rbox-opening-li-title:before {
    content: '';
    background-image: url(https://yvy.yeled.org/wp-content/uploads/2020/09/arow-2-2.png);
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    position: absolute;
    left: -18px;
    top: 50.5%;
    transform: translateY(-50%);
    background-position: top;
    background-size: contain;
}

.home .rbox-job-shortdesc,
.page-parent .rbox-job-shortdesc {
    color: #fff;
    font-size: 14px;
    margin-bottom: 6px;
}

.home .rbox-opening-li:after,
.page-parent .rbox-opening-li:after {
    content: '';
    height: 1px;
    background: #fff;
    width: 100%;
    display: block;
}

.home small.rbox-opening-position-type,
.page-parent small.rbox-opening-position-type {
    border: 0;
    padding: 0;
}

.home span.rbox-opening-position-info,
.page-parent span.rbox-opening-position-info {
    position: absolute;
    top: 0;
    left: 31px;
    margin-top: -11px;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
    line-height: 15px;
}

#gallery-slider.owl-theme .owl-nav.disabled+.owl-dots {
    display: none;
}

#gallery-slider.owl-carousel .owl-item img {
    height: 250px;
    object-fit: cover;
}

.home .rbox-jobs-group+.rbox-jobs-group,
.page-parent .rbox-jobs-group+.rbox-jobs-group {
    margin-top: 0;
}

.job-script {
    padding-bottom: 20px
}

.career-text a {
    color: #29beff;
}

.newsletter-date p {
    font-size: 10px;
    line-height: 13px;
    color: #635465;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 10px;
}

.newsletter-title h2 {
    margin-top: 0;
    margin-bottom: 0;
    color: #37265b;
    font-size: 24px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: -0.4px;
}

.newsletter-des p {
    color: #868586;
    font-size: 14px;
    margin-top: 26px;
    padding-right: 17px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 11px;
}

a.newsletter-read {
    color: #29beff;
    font-size: 13px;
    font-weight: 700;
}

.newsletter-sub {
    padding: 40px 40px 30px;
    text-align: center;
    background: #DCDCDC;
}

p.newsletter-sub-title {
    color: #635465;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.1px;
    margin-left: -6px;
    margin-bottom: 21px;
}

.emaillist {
    margin-bottom: 23px;
}

.es_subscription_form input[type="submit"] {
    border-radius: 33px;
    padding: 11px 24px;
    font-size: 13px;
    margin-left: -6px !important;
    background: #37265b;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    color: #666;
    border: 1px solid #e6e6ea;
    border-radius: 3px;
    padding: 0.5rem;
    background-color: #f6f6fa;
}

input.es_required_field.es_txt_email.ig_es_form_field_email {
    width: 98%;
    border-radius: 22px;
    background: #fff;
    margin-left: -6px;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 9px;
    color: #b4b2b6;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border: 0;
}

.es_subscription_form label {
    font-size: 0;
}

.es_subscription_form input[type="submit"] {
    border-radius: 33px;
    padding: 11px 26px;
    font-size: 13px;
    margin-left: -6px !important;
    background: #37265b;
    border: 0;
    color: #fff;
    font-weight: 700;
}

p.newsletter-sub-detail {
    font-size: 14px;
    margin-left: -6px;
    margin-top: 18px;
    font-weight: 600;
    color: #b4b2b6;
    margin-bottom: 0;
}

:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

#gallery-owl.owl-carousel .owl-item img {
    height: auto;
    object-fit: cover;
}

#gallery-owl .owl-dot {
    display: none;
}

html {
    scroll-behavior: smooth;
}

.home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date {
    width: 30px;
    left: -10px;
    top: -8px;
}

.sidebar-events-list {
    margin-top: 12px;
}

.sidebar-events-list #event-listing-view {
    padding-right: 15px;
    padding-left: 15px;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner .wpem-event-banner-img {
    height: 127px;
    width: 100%;
    border-radius: 0;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner {
    margin-top: 10px;
    padding-right: 0 !important;
}

.sidebar-events div#event-listing-view {
    overflow: hidden;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper {
    border: 0;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner {
    margin-top: 10px;
}

.sidebar-events .wpem-event-date {
    display: none;
}

.sidebar-events span.wpem-event-date-time-text {
    font-size: 10px;
    color: #37265b;
    font-weight: 700;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #37265b;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
}

.sidebar-events .wpem-event-location span {
    color: #868586;
    font-size: 11px;
    line-height: 14px;
    margin-top: 5px;
    font-weight: 600;
    margin-left: 1px;
    letter-spacing: -0.2px;
}

.sidebar-events .wpem-event-description p {
    color: #868586;
    font-size: 11px;
    line-height: 18px;
    margin-top: 5px;
    font-weight: 400;
    margin-left: 1px;
    letter-spacing: -0.2px;
}

.sidebar-events .wpem-event-action-url.event-register-btn {
    right: auto;
    color: #29beff !important;
    font-size: 12px;
    font-weight: 700;
    margin-top: 0;
    border-bottom: 1px solid #29beff !important;
    padding-bottom: 8px !important;
    top: auto;
    opacity: 1;
    display: inline-block;
    bottom: 3px;
    border-radius: 0;
    width: 98% !important;
    padding-left: 13px !important;
    border-top: 0 !important;
    border-right: 0 !important;
    position: relative;
    font-family: 'Nunito', sans-serif;
}

    .sidebar-events .wpem-event-action-url.event-register-btn:before {
        content: '';
        /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
        background: url(../../images/footer-arrow.png);
        display: block;
        width: 7px;
        height: 12px;
        background-position: center;
        background-size: contain;
        position: absolute;
        top: 12%;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
    }

.sidebar-events .wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper {
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0), 0 0 0 0px rgba(0, 0, 0, 0);
}

.sidebar-events .wpem-event-listings.wpem-event-listing-box-view .wpem-event-box-col {
    padding-bottom: 0px;
}

.wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    content: "";
}

.sidebar-events .wpem-event-date-time {
    display: block;
}

.page-sidebar .sidebar-events .wpem-event-details {
    padding-top: 0px !important;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-date-time::before {
    display: none;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    display: none;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location,
.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-date-time {
    padding-left: 0;
}

.sidebar-events .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #37265b !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin-bottom: 0 !important
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
    padding-left: 52px;
    color: #588fcb;
    margin-top: -47px;
    position: relative;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    display: block;
    padding: 12px 0px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #588fcb !important;
    font-weight: 700;
    line-height: 31px;
    margin-bottom: 10px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-month {
    color: #635465;
    font-weight: 700;
    font-size: 10px;
    margin-top: -3px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-date {
    color: #37265b;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-action-url {
    padding: 12px 0px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location::before {
    /*content: "";*/
    color: #fff !important;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-date {
    color: #37265b;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date .wpem-event-date-type .wpem-from-date .wpem-month {
    color: #635465;
    font-weight: 700;
    font-size: 10px;
    margin-top: -3px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
    padding-left: 52px;
    color: #588fcb;
    margin-top: -47px;
    position: relative;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title {
    margin-bottom: 0;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #588fcb;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
    font-size: 24px !important;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-location {
    /*padding-left: 0;*/
    margin: 0 !important;
    color: #635465;
    font-weight: 600;
    margin-top: -5px;
    letter-spacing: -0.4px;
}

.innerpage-event .wpem-event-listing-list-view .wpem-event-box-col {
    margin-bottom: 30px;
    min-height: 155px;
}

.page-parent .innerpage-event .wpem-event-description p {
    color: #635465;
}

.innerpage-event .wpem-event-description p {
    color: #fff;
    margin-bottom: 0;
}

.innerpage-event .event-register-btn:hover {
    border: 1px solid #37265b;
    width: max-content !important;
    padding: 1px 17px !important;
    color: #37265b;
}

.innerpage-event .event-register-btn {
    color: #fff !important;
    border: 1px solid #fff;
    width: max-content !important;
    padding: 1px 17px !important;
    border-radius: 20px;
    font-weight: 600;
    font-size: 13px;
    font-family: 'Open Sans';
    margin-top: -3px;
}

.innerpage-event .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details:before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/Trazado-62.png);
    background-repeat: repeat;
    background-position: right;
    background-size: cover;
    padding-left: 0;
    margin-left: -9px;
    top: 9px;
    background: #588fcb;
}

.page-template-fitness-center ul.group-topic-list li {
    width: 16.1%;
}

.page-template-fitness-center ul.group-topic-list li:nth-child(2) {
    width: 23.6%;
    padding-left: 22px;
    padding-right: 10px;
}

.page-template-fitness-center ul.group-topic-list li:nth-child(3) {
    width: 31.4%;
    padding-left: 13px;
    padding-right: 20px;
}

.page-template-fitness-center ul.group-topic-list li:last-child {
    text-align: center;
    padding-left: 32px;
    padding-right: 30px;
    width: 19%;
}

.page-template-fitness-center .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/fitness-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-fitness-center ul.group-topic-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/fitness-caret.png);
    width: 18px;
    height: 17px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.page-child #news-events .news-content-excerpt a.news-more-link:after {
    background: 0;
}

.page-template-early-childhood-sub-child #news-events .news-content-excerpt a.news-more-link:after {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/readmore-arrow-1.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.fitness-round-btn {
    padding: 6px 21px 6px 19px;
    background: #4b1469;
    font-size: 14px;
    border-radius: 26px;
}

.fitness-round-btn:hover {
    color: #fff;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    background-color: transparent;
}

.benefits-section .list-group-item {
    color: #37265b;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px !important;
}

.benefits-section .list-group-item h5 {
    font-weight: 600;
}

.page-template-special-education .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/special-education-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-special-education ul.group-topic-list li:first-child {
    padding-left: 22px;
    padding-right: 20px;
}

.page-template-special-education ul.group-topic-list li:nth-child(2) {
    width: 15.6%;
    padding-left: 15px;
    padding-right: 15px;
}

.page-template-special-education ul.group-topic-list li:nth-child(3) {
    width: 12.4%;
    padding-left: 23px;
    padding-right: 20px;
}

.page-template-special-education ul.group-topic-list li:nth-child(4) {
    width: 19.8%;
    padding-left: 21px;
    padding-right: 20px;
}

.page-template-special-education .second-level-menu {
    border-top: 4px solid #e23d3b;
}

.page-template-special-education ul.group-topic-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/special-education-caret.png);
    width: 18px;
    height: 17px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.page-template-student-services .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/student-down.png);
    background-position: center;
    background-size: contain;
}

.page-template-student-services .second-level-menu {
    border-top: 4px solid #f5865c;
}

.page-template-community-services .second-level-menu {
    border-top: 4px solid #CE9367;
}

.page-template-community-services .group-title-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/broun-group-1.png);
    background-position: center;
    background-size: contain;
}

.page-template-community-services ul.group-topic-list li.active:before {
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/brown-down-caret.png);
    width: 18px;
    height: 17px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.page-template-community-services ul.group-topic-list {
    padding-left: 0;
    text-align: center;
}

.page-template-community-services ul.group-topic-list li {
    width: 16.1%;
}

.page-template-community-services ul.group-topic-list li:nth-child(2) {
    width: 18.6%;
    padding-left: 22px;
    padding-right: 10px;
}

.page-template-community-services ul.group-topic-list li:nth-child(3) {
    text-align: center;
    width: 29.4%;
    padding-left: 43px;
    padding-right: 40px;
}

.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    object-fit: cover;
    width: auto;
    object-position: right;
    max-width: 100%;
    min-height: 100%;
    padding-left: 0;
}

.non-feature-card .card-img-top {
    padding-left: 15px;
}

.cart-dec {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    margin-bottom: 19px;
    padding: 10px 18px 0 17px;
}

#gmap_canvas {
    width: 600px;
    height: 500px;
}

.mapouter {
    float: left;
}

.director-phone-card i {
    color: #4abab8;
    font-size: 11px;
    margin-right: 3px;
}

/*template color css*/
.page-template-fitness-center .owner-chat,
.page-template-fitness-center .director-email i,
.page-template-fitness-center .director-phone i,
.page-template-fitness-center .main-sub-heading,
.page-template-fitness-center .director-email-card i,
.page-template-fitness-center .director-phone-card i {
    color: #37a564;
}

.page-template-fitness-center .section-heading:after {
    border-bottom: 2px solid #37a564;
}

.page-template-special-education .owner-chat,
.page-template-special-education .director-email i,
.page-template-special-education .director-phone i,
.page-template-special-education .main-sub-heading,
.page-template-special-education .director-email-card i,
.page-template-special-education .director-phone-card i {
    color: #e23d3b;
}

.page-template-special-education .section-heading:after {
    border-bottom: 2px solid #e23d3b;
}

.page-template-student-services .owner-chat,
.page-template-student-services .director-email i,
.page-template-student-services .director-phone i,
.page-template-student-services .main-sub-heading,
.page-template-student-services .director-email-card i,
.page-template-student-services .director-phone-card i {
    color: #f5865c;
}

.page-template-student-services .section-heading:after {
    border-bottom: 2px solid #f5865c;
}

.page-template-community-services .owner-chat,
.page-template-community-services .director-email i,
.page-template-community-services .director-phone i,
.page-template-community-services .main-sub-heading,
.page-template-community-services .director-email-card i,
.page-template-community-services .director-phone-card i {
    color: #CE9367;
}

.page-template-community-services .section-heading:after {
    border-bottom: 2px solid #CE9367;
}

/*template color css*/


#myModal .modal-content {
    max-width: 700px;
    margin: 20px auto;
}

#myModal .rbox-top-links,
#myModal .rbox-bottom-links {
    display: none;
}

#myModal .close {
    margin-top: 1px;
    font-size: 24px;
    opacity: 1;
    margin-right: 7px;
    cursor: pointer;
}

.page-child #myModal .rbox-opening-position-info:before,
.home #myModal .rbox-opening-position-info:before {
    background: 0;
}

.page-child #myModal .rbox-opening-position-info:after,
.home #myModal .rbox-opening-position-info:after {
    font-size: 0
}

#myModal .rbox-job-fullpage,
#myModal .rbox-job-fullpage p,
#myModal .rbox-job-fullpage li {
    color: #635465;
}

.home #myModal .rbox-job-shortdesc {
    color: #635465;
}

#myModal .rbox-apply-button {
    background: #37265b;
    color: #fff;
    padding: 6px 23px;
    border-radius: 20px;
    border: 0;
}

#myModal .rbox-buttons {
    text-align: center;
}

.page-child #myModal .rbox-opening-position-type {
    font-size: 14px;
    color: #635465;
}

.type-news .entry-title {
    text-transform: capitalize;
    font-weight: 700;
    color: #37265b;
    font-size: 32px;
}

.event_listing .entry-title {
    text-transform: capitalize;
    font-weight: 700;
    color: #37265b;
    font-size: 32px;
}

.single .widget-area {
    background-color: #F0F0F0;
    padding-right: unset;
    padding: 30px 36px;
    margin-top: 40px;
}

.widget_search .search-form .screen-reader-text {
    display: none;
}

.widget-area .widget {
    padding: 10px 0;
}

.single .site-footer {
    margin-top: 40px;
}

h2.widget-title {
    font-size: 20px;
    font-weight: 600;
}

.widget a {
    color: #635465;
}

.search-form .search-submit {
    background: #37265b;
    color: #fff;
    padding: 6px 17px;
    border-radius: 20px;
    font-weight: 600;
    border: 0;
}

.single .post-thumbnail img {
    width: 500px;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 330px;
    object-fit: cover;
}

.single .post-navigation .screen-reader-text {
    display: none;
}

.single .post-navigation {
    margin-top: 50px;
}

.single-event_listing .widget-area {
    display: none;
}

.page-id-68 .entry-header,
.page-id-68 .widget-area,
.page-id-815 .entry-header {
    display: none;
}

.page-id-68 .site-main,
.page-id-815 .site-main {
    padding-top: 40px;
}

.news-col-3 {
    margin: 0 15px;
    box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.1), 0 2px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e6e6;
    padding: 0 !important;
    width: 30.333333% !important;
    min-height: 432px;
}

.news-col-3 .news .news-title a {
    font-size: 20px;
    line-height: 22px;
    color: #37265b;
    text-decoration: none !important;
    text-transform: capitalize;
    font-weight: 600;
}

.news-col-3 .news-content {
    padding: 0 15px 20px;
}

.news-col-3 .news-content-excerpt .news-more-link {
    position: relative;
    left: 0;
    top: 0;
}

.news-col-3 .grid-date-post {
    font-size: 10px;
    font-weight: 700;
}

.space30 {
    margin-top: 30px;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper {
    box-shadow: 0 2px 8px -3px rgba(0, 0, 0, 0.1), 0 2px 6px -2px rgba(0, 0, 0, 0.05);
    border: 0 !important;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
    font-size: 20px !important;
    line-height: 22px !important;
    color: #37265b !important;
    text-transform: capitalize;
    font-weight: 700 !important;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
    padding-top: 0;
}

.wpem-main a:active,
.wpem-main a:focus,
.wpem-main a:hover {
    color: #37265b !important;
}

.wpem-main a {
    color: #635465 !important;
}

.wpem-event-action-url.event-register-btn {
    color: #37265b !important;
    border: 1px solid #37265b;
    width: max-content !important;
    padding: 1px 17px !important;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Open Sans';
    margin-left: 0;
    border-left: 1px solid #ccc !important;
}

.wpem-event-listings.wpem-event-listing-box-view .wpem-event-description {
    display: none;
}

.page-child .wpem-event-listings.wpem-event-listing-box-view .wpem-event-description {
    display: block;
}

.news-more-link:hover {
    background: transparent !important;
    color: #29BEFF !important;
}

.single .widget-area {
    padding: 30px 26px;
}

.parent-page-card .card-img-top {
    height: 160px;
    min-height: auto;
    margin-bottom: 7px;
}

.parent-page-card .card-text {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 0;
    padding-bottom: 9px;
}

.parent-page-card .card-link {
    padding-left: 20px;
    font-size: 13px;
    line-height: 17px;
    padding-bottom: 5px;
    position: relative;
}

.parent-page-card .card-link a {
    color: #29BEFF;
    font-weight: 800;
}

.parent-page-card .card-link a:after {
    content: '';
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/readmore-arrow-1.png);
    display: block;
    width: 43px;
    height: 10px;
    background-position: center;
    background-size: 100%;
    position: absolute;
    top: 42%;
    left: 42%;
    right: 0;
    transform: translateY(-50%);
}

.non-feature-card .card-body .list-group-flush .list-group-item:first-child {
    border-top: 1px solid #A7C7EA !important;
}

.non-feature-card .card-body .list-group-flush .list-group-item:last-child {
    border-bottom: 1px solid #A7C7EA !important;
}

.non-feature-card .card-body .list-group-flush .list-group-item {
    padding: 8px 0 9px 19px !important;
}

.non-feature-card .card-body .list-group-flush {
    padding: 10px 98px 0 17px;
}

.non-feature-card.featured-card-group .card-body img {
    max-height: 330px;
    min-height: auto;
    width: 100%;
}

.second-sublevel-menu {
    background-color: #D4D4D4;
    border-top: 8px solid #4b1469;
}

ul.group-sub-topic-list {
    list-style: none;
    padding-left: 31px;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 0;
}

ul.group-sub-topic-list li {
    display: inline-block;
    padding-left: 16px;
    padding-right: 16px;
    width: 24.1%;
    text-align: center;
    border-left: 1px solid #9a9a9a;
    margin-bottom: 0;
    padding-top: 12px;
    padding-bottom: 4px;
    line-height: 21px;
}

ul.group-sub-topic-list li a {
    font-size: 15px;
    color: #4b1469;
    font-weight: 600;
    line-height: 13px;
    font-family: 'Montserrat', sans-serif;
}

ul.group-sub-topic-list li.active {
    background: #37C4BD;
    position: relative;
}

ul.group-sub-topic-list li.active:before {
    top: -3px;
    content: '';
    background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/purple-caret.png);
    width: 18px;
    height: 17px;
    display: block;
    position: absolute;
    margin: 0 auto;
    text-align: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 46%;
}

ul.group-sub-topic-list li a.active {
    color: #fff;
}

ul.group-sub-topic-list li:first-child {
    border-left: 0;
}

.page-template-special-education-sub-child ul.group-sub-topic-list li.active {
    background: #e23d3b;
    position: relative;
}

.page-template-student-services-sub-child ul.group-sub-topic-list li.active {
    background: #f5865c;
    position: relative;
}

.page-template-community-services-sub-child ul.group-sub-topic-list li.active {
    background: #b7805c;
    position: relative;
}

.page-template-fitness-center-sub-child ul.group-sub-topic-list li.active {
    background: #37a564;
    position: relative;
}

#event-listing-view .wpem-event-box-col {
    display: none;
}

#event-listing-view .wpem-event-box-col:nth-child(-n+3) {
    display: list-item;
}

.sidebar-events-list #event-listing-view .wpem-event-box-col:nth-child(-n+2) {
    display: list-item;
}

.sidebar-events-list #event-listing-view .wpem-event-box-col:nth-child(3) {
    display: none;
}

.footer-sitelinks {
    padding-left: 0;
    text-align: right;
}

.footer-sitelinks li {
    display: inline-block;
}

.footer-sitelinks li a {
    color: #a29fa3;
    font-weight: 300;
    font-size: 13px;
    text-transform: uppercase;
}

.footer-sitelinks li:last-child:after {
    content: '';
}

.last-right-pd {
    padding-right: 0;
}

.footer-main-address-title {
    color: #635465;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
}

.footer-main-address p {
    color: #635465;
}

.footer-main-address img {
    width: auto;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 2px;
}

.white-nextarrow {
    width: 9px;
    vertical-align: inherit;
    margin-left: 4px;
    margin-top: -2px;
}

.read-more-straight-arrow {
    width: 57px;
    vertical-align: middle;
    margin-left: 2px;
}

.page-child .type-news {
    display: none;
}

.page-child .type-news:nth-child(-n+2) {
    display: block;
}

.page-child #event-listing-view .wpem-event-box-col {
    display: none;
}

.page-child #event-listing-view .wpem-event-box-col:nth-child(-n+2) {
    display: block;
}

.page-child #event-listing-view .wpem-event-box-col:nth-child(3) {
    display: none;
}

.page-child #event-listing-view .wpem-event-action-url.event-register-btn {
    color: #fff !important;
    border: 1px solid #fff;
}

.page-child .page-sidebar #event-listing-view .wpem-event-action-url.event-register-btn {
    color: #29beff !important;
    border: 1px solid #29beff;
}

.page-child .grid-date-post a {
    color: #fff;
}

.page-child #news-events {
    margin-bottom: 130px;
}

.news-content .grid-date-post a {
    color: #588fcb;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.4px;
}

.event_listings a.load_more_events {
    display: none !important;
}

.wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner .wpem-event-banner-img {
    border-radius: 0 !important
}

.home .news-col-2 {
    margin-bottom: 27px;
}

.home-news-events p.sell-all-btn {
    margin-top: 0;
}

.news-event-title h2 {
    color: #4b1469;
    text-transform: capitalize;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;
    font-size: 38px;
    margin-bottom: 0;
}

.news-event-title h2:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 17px;
}

.news-page-detail {
    margin-top: 34px;
}

.news-page-detail #event-listing-view .wpem-event-box-col {
    display: block;
}

.news-page-detail .type-news {
    padding: 0;
    margin: 0 13px 30px;
    border-bottom: 8px solid #4b1469;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.news-page-detail .news-col-2 {
    width: 46%;
}

.news-page-detail .news-content {
    padding: 20px 20px 23px;
}

.news-page-detail .grid-date-post {
    font-size: 0;
}

.news-page-detail .news-content .grid-date-post a {
    font-weight: 800;
}

.news-page-detail .news .news-title {
    font-size: 18px;
    line-height: 22px;
    color: #37265b;
    text-decoration: none !important;
    font-weight: 700;
    letter-spacing: -0.4px;
    min-height: 66px;
}

.news-page-detail .news .news-title a {
    font-size: 18px;
    line-height: 22px;
    color: #37265b;
    text-decoration: none !important;
}

    .news-page-detail .news-content-excerpt a.news-more-link:after {
        content: '';
        /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
        background: url(../../images/footer-arrow.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
    }

.news-event-pade-sidebar .sidebar-events {
    padding-top: 15px;
}

.news-event-pade-sidebar .wpem-event-listings.wpem-event-listing-box-view .wpem-event-description {
    display: block;
}

.news-event-pade-sidebar .sidebar-events .wpem-event-action-url.event-register-btn:before {
    background: 0;
}

.news-event-pade-sidebar .sidebar-events .wpem-event-details .wpem-event-action-url.event-register-btn {
    border: 1px solid #37265b !important;
    width: max-content !important;
    padding: 4px 17px !important;
    color: #37265b !important;
    margin-top: 6px;
    border-radius: 20px;
}

.news-event-pade-sidebar .wpem-event-description p {
    display: none;
}

.news-event-pade-sidebar .wpem-event-description p:nth-child(-n+1) {
    display: block;
}

.news-event-pade-sidebar .wpem-event-infomation {
    padding-right: 15px;
}

.news-event-pade-sidebar .sidebar-events span.wpem-event-date-time-text {
    font-size: 12px;
}

.news-event-pade-sidebar .wpem-event-date-time {
    margin-left: 0;
}

.news-event-pade-sidebar .wpem-event-location .wpem-event-location-text {
    font-size: 14px;
    font-weight: 400;
}

.news-event-pade-sidebar #event-listing-view {
    margin-right: 15px;
}

.news-event-pade-sidebar #event-listing-view .wpem-event-infomation {
    border-bottom: 1px solid #29BEFF;
    padding-bottom: 10px;
}

.news-event-pade-sidebar .wpem-event-box-col {
    margin-bottom: 20px;
}

.news-event-pade-sidebar .page-sidebar .wpem-event-banner {
    padding-right: 7px !important;
}

.news-event-pade-sidebar .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-category {
    margin: 0;
    padding: 0;
}

.news-event-pade-sidebar .wpem-event-category h6 {
    color: #588fcb;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.4px;
}

.news-template-default .post-thumbnail img {
    width: 100%;
    margin-top: 0;
    object-position: center;
    height: 560px;
}

.news-template-default .type-news.has-post-thumbnail {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-bottom: 8px solid #4b1469;
    padding: 40px 35px;
    background: #fff;
}

.news-template-default .type-news .entry-title:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 17px;
}

.news-template-default h2.widget-title {
    font-size: 23px;
    font-weight: 600;
    color: #37265b;
}

.news-template-default h2.widget-title:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 17px;
}

.news-template-default .recent-news-items ul {
    padding-left: 0;
}

.news-template-default .news_li a.newspost-title,
.news-template-default .widget .news_li a.newspost-title,
.news-template-default #secondary .widget .news_li a.newspost-title {
    color: #37265b;
    font-weight: 600;
}

.news-template-default .widget-date-post a {
    color: #588fcb;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.4px;
}

.news-template-default li.news_li {
    border-bottom: 1px solid #29BEFF !important;
}

.news-template-default li.news_li:last-child {
    border-bottom: 0 solid #29BEFF !important;
}

.news-template-default .nav-previous .nav-title,
.news-template-default .nav-next .nav-title {
    display: none;
}

.news-template-default .nav-previous .nav-subtitle,
.news-template-default .nav-next .nav-subtitle {
    color: #29BEFF;
}

.news-template-default .nav-previous a:before {
    content: '';
    background-image: url(https://yvy.yeled.org/wp-content/uploads/2020/10/footer-arrow-swap.png);
    background-repeat: no-repeat;
    width: 11px;
    height: 13px;
    background-position: top;
    background-size: contain;
    display: inline-block;
    position: relative;
    margin-right: 3px;
    top: 1px;
}

.news-template-default .nav-previous .nav-subtitle,
.news-template-default .nav-next .nav-subtitle {
    font-size: 0;
}

.news-template-default .nav-previous .nav-subtitle:before {
    content: 'Previous';
    font-size: 1rem;
    font-weight: 400;
    color: #29BEFF;
}

.news-template-default .nav-next .nav-subtitle:before {
    content: 'Next';
    font-size: 1rem;
    font-weight: 400;
    color: #29BEFF;
}

.news-template-default .nav-next a:after {
    content: '';
    /*background-image: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
    background-image: url(../../images/footer-arrow.png);
    background-repeat: no-repeat;
    width: 11px;
    height: 13px;
    background-position: top;
    background-size: contain;
    display: inline-block;
    position: relative;
    margin-right: 3px;
    top: 2px;
}

.news-template-default .nav-links .nav-previous,
.news-template-default .nav-links .nav-next {
    display: inline-block;
}

.news-template-default .nav-links .nav-next {
    float: right;
}

.news-template-default .event-new-detail-page {
    margin-top: -220px;
    z-index: 9;
    position: relative;
}

.news-template-default .type-news .entry-title {
    margin-bottom: 30px;
}

.news-template-default .navbar {
    margin-top: 25px;
}

.news-template-default .post-thumbnail {
    margin-top: -118px;
}

#secondary.widget-area {
    display: none;
}

.single-news #secondary.widget-area {
    display: block;
}

.page-id-809 .entry-header {
    display: none;
}

.page-id-809 .site-main {
    padding-top: 40px;
}

.news-seperate-page .news_pagination {
    display: block !important;
}

.news-page-detail .news_pagination a,
.news-page-detail .news_pagination a {
    color: #635465 !important;
    background: transparent;
    border: 1px solid #fff;
}

.news_pagination .page-numbers.current {
    background: #4b1469;
    padding: 3px 8px;
    border: 1px solid #4b1469;
    color: #fff;
}

.news-event-pade-sidebar .event-seperate-page #event-listing-view .wpem-event-infomation {
    border-bottom: 0;
    padding-bottom: 15px;
    margin-top: -17px;
}

.event-seperate-page .wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper {
    border-bottom: 4px solid #4b1469 !important;
}

.event-seperate-page .wpem-event-date {
    display: none !important;
}

.event-seperate-page .wpem-event-listings.wpem-event-listing-box-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
    padding-top: 0;
}

.event-seperate-page .wpem-event-category {
    display: none;
}

.event-seperate-page .wpem-event-description {
    margin: 5px;
}

.event-seperate-page .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-date-time::before {
    display: none;
}

.event-seperate-page .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-date-time {
    padding-left: 0px;
}

.event-seperate-page .wpem-event-date-time .wpem-event-date-time-text {
    color: #37265b !important;
    font-size: 13px;
    font-weight: 600;
}

.event-seperate-page .wpem-event-action-url.event-register-btn {
    padding: 5px 17px !important;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.event_registration.registration {
    display: none;
}

.news-page-detail .news-content-excerpt .news-short-content,
.news-page-detail .wpem-event-description p,
.page-child .news-section .news-short-content,
.page-child .sidebar-events .wpem-event-description p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.news-page-detail .news-content-excerpt .news-short-content {
    min-height: 54px;
}

.home .news-events-detail .news-short-content,
.home .wpem-event-description p,
.page-parent .news-events .news-short-content,
#event-listing-view .wpem-event-description p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.page-child .wpem-event-category {
    display: none !important;
}

.single-event_listing .wpem-single-event-short-info .event-category {
    color: #5090d6;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-single-event-short-info {
    padding-left: 30px;
    padding-right: 30px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper {
    border: 0px solid #e4e4e4;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top {
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-bottom: 8px solid #4b1469;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-event-details .wpem-event-title .wpem-heading-text {
    color: #5090d6;
    font-weight: 800;
    font-size: 33px;
    margin-top: 5px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-event-details .wpem-event-title {
    margin-bottom: 0;
}

.single-event_listing .wpem-event-date-time .wpem-event-date-time-text {
    color: #351763;
    font-size: 22px;
    font-weight: 800;
}

.single-event_listing .location-event-address a,
.single-event_listing .location-event-address div {
    color: #44394c !important;
    font-size: 20px;
}

.single-event_listing .location-event-address div {
    padding-left: 18px;
}

.single-event_listing .location-event-address a:before,
.single-event_listing .location-event-address div:before {
    content: "\e947";
    font-family: wp-event-manager;
    position: absolute;
    left: 29px;
    font-size: 15px;
    line-height: 23px;
    color: #37265b;
}

.single-event_listing .location-event-address {
    margin-top: 6px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-footer .wpem-organizer-profile-wrapper .wpem-organizer-profile .wpem-organizer-logo-wrapper {
    display: none;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-footer .wpem-organizer-profile-wrapper {
    border-top: 0px solid #e4e4e4;
    padding: 0;
    text-align: left;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-footer .wpem-organizer-profile-wrapper .wpem-organizer-profile .wpem-organizer-social-links {
    display: none;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-sidebar-info {
    margin-bottom: 15px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-footer .wpem-organizer-profile-wrapper .wpem-organizer-profile .wpem-organizer-short-info {
    font-size: 17px;
    color: #44394c !important;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-event-single-image-wrapper .wpem-event-single-image img {
    height: 100%;
    object-fit: cover;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-event-single-image {
    height: 100%;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-event-single-image-wrapper {
    height: 100%;
}

.single-event_listing .friends-share {
    color: #868586;
    font-size: 14px;
    font-weight: 600 !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.single-event_listing .event-share-custom {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.single-event_listing .event-share-custom::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #351763;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
}

.single-event_listing .event-share-custom .friends-share,
.single-event_listing .event-share-custom .wpem-share-this-event {
    background: #fff;
    position: relative;
}

.single-event_listing .event-share-custom .friends-share {
    padding-right: 20px;
    margin-bottom: 0;
}

.single-event_listing .event-share-custom .wpem-share-this-event {
    padding-left: 20px;
    width: auto;
    margin-top: auto;
    margin-bottom: auto
}

.single-event_listing .wpem-single-event-page .wpem-share-this-event .wpem-event-share-lists .wpem-social-icon {
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    height: 19px;
    width: 19px;
}

.single-event_listing .wpem-single-event-page .wpem-share-this-event .wpem-event-share-lists .wpem-social-icon.wpem-linkedin a::before,
.single-event_listing .wpem-single-event-page .wpem-share-this-event .wpem-event-share-lists .wpem-social-icon.wpem-facebook a::before {
    background-color: #351763;
    height: 19px;
    width: 19px;

}

.single-event_listing .wpem-single-event-page .wpem-share-this-event .wpem-event-share-lists .wpem-social-icon.wpem-linkedin a,
.single-event_listing .wpem-single-event-page .wpem-share-this-event .wpem-event-share-lists .wpem-social-icon.wpem-facebook a {
    background: transparent;
    color: #351763 !important;
    height: 19px;
    width: 19px;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-header-top .wpem-event-details {
    margin-bottom: 20px;
}

.single-event_listing .share-div-custom {
    position: absolute;
    width: 82%;
    bottom: 20px;
}

.single-event_listing .wpem-single-event-body {
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-bottom: 8px solid #4b1469;
    margin-top: 40px;
    border-top: 1px solid #f1efef;
}

.single-event_listing .wpem-single-event-page .wpem-single-event-wrapper .wpem-single-event-body .wpem-single-event-left-content .wpem-single-event-body-content {
    padding: 30px 40px;
}

.single-event_listing .navigation.post-navigation {
    display: none;
}

.single-event_listing .event-back-btn {
    padding: 0;
    background: transparent;
    border: 0;
    color: #29BEFF;
    font-weight: 600;
    margin: 0 auto;
    font-size: 15px;
}

.single-event_listing .wpem-single-event-body-content p {
    font-size: 17px;
    color: #44394c !important;
}

.single-event_listing .event-back-div {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: center;
}

.single-event_listing .entry-header {
    display: none;
}

.mb-30 {
    margin-bottom: 30px;
}

.single-event_listing .event-list-short-desc {
    display: none;
}

.wpem-event-description {
    max-height: 44px;
    overflow: hidden;
}

.home .wpem-event-description {
    max-height: 36px;
    overflow: hidden;
}

.page-id-645 .entry-header {
    display: none;
}

.page-id-645 .event-new-detail-page,
.page-template-contact-php .contact-page {
    margin-top: 40px;
}
/*
.contact-title {
    background: #4abab8;
    font-size: 28px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 0;
    padding: 18px 21px;
    line-height: 1;
}

.parent-page-title {
    font-size: 21px;
    color: #4abab8;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 0;
}*/

.contact-director-name {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0;
}

/*.contact-social-icons {
    padding-left: 0;
    list-style: none;
    display: inline-block;
    float: right;
    margin-bottom: 0;
}

.contact-social-icons li {
    display: inline-block;
    color: #4abab8;
}

.contact-social-icons li a {
    color: #4abab8;
}*/

.director-contact {
    position: relative;
    height: 100%
}

.director-contact a {
    position: absolute;
    bottom: 0;
    color: #44394C;
    font-weight: bold
}

.contact-box {
    border-bottom: 1px solid #4abab8;
    padding-bottom: 7px;
    margin-bottom: 15px;
}

.mt-28 {
    margin-top: 28px;
}

.subparent-page-title {
    color: #929094;
    margin-bottom: 0;
    font-size: 15px;
    height: 17px;
    line-height: 1.2;
}

#special_education-contact .contact-title {
    background: #e23d3b;
}

#special_education-contact .parent-page-title,
#special_education-contact .contact-social-icons li,
#special_education-contact .contact-social-icons li a {
    color: #e23d3b;
}

#special_education-contact .contact-box {
    border-bottom: 1px solid #e23d3b;
}

#special_education-contact {
    margin-top: 20px;
}

#student_success-contact .contact-title {
    background: #f5865c;
}

#student_success-contact .parent-page-title,
#student_success-contact .contact-social-icons li,
#student_success-contact .contact-social-icons li a {
    color: #f5865c;
}

#student_success-contact .contact-box {
    border-bottom: 1px solid #f5865c;
}

#student_success-contact {
    margin-top: 20px;
}

#fitness_health-contact .contact-title {
    background: #37a564;
}

#fitness_health-contact .parent-page-title,
#fitness_health-contact .contact-social-icons li,
#fitness_health-contact .contact-social-icons li a {
    color: #37a564;
}

#fitness_health-contact .contact-box {
    border-bottom: 1px solid #37a564;
}

#fitness_health-contact {
    margin-top: 20px;
}

#community_social-contact .contact-title {
    background: #b7805c;
}

#community_social-contact .parent-page-title,
#community_social-contact .contact-social-icons li,
#community_social-contact .contact-social-icons li a {
    color: #b7805c;
}

#community_social-contact .contact-box {
    border-bottom: 1px solid #b7805c;
}

#community_social-contact {
    margin-top: 20px;
    margin-bottom: 40px;
}

.career-banner-div {
    position: relative;
    margin-top: -118px;
}

.career-banner img {
    max-height: 560px;
    width: 100%;
    object-fit: cover;
}

.page-template-career .navbar,
.page-template-enroll .navbar,
.page-template-board-of-governance .navbar {
    margin-top: 25px;
}

.career-text-box {
    position: absolute;
    background: #fff;
    bottom: 0;
    padding: 40px;
    width: 50%;
}

.career-text-box p {
    color: #868586;
    margin-top: 20px;
    margin-bottom: 0;
}

.career-inner h1 {
    color: #5090d6;
    font-size: 35px;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

.career-inner h1:after {
    border-bottom: 2px solid #AFD2EF;
    content: '';
    display: block;
    padding-top: 10px;
}

.career-inner {
    margin-top: 60px;
}

.career-job-script {
    margin-top: 30px;
}

.career-job-script .rbox-opening-li-title,
.career-job-script .rbox-opening-li a {
    font-size: 29px;
    font-weight: bold;
}

    .career-job-script .rbox-opening-li:before {
        content: '';
        /*background-image: url(https://yvy.yeled.org/wp-content/uploads/2020/09/footer-arrow.png);*/
        background-image: url(../../images/footer-arrow.png);
        background-repeat: no-repeat;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 0;
        top: 12%;
        transform: translateY(-0%);
        background-position: top;
        background-size: contain;
    }

.career-job-script .rbox-opening-li {
    position: relative;
    padding-left: 17px !important;
    padding-bottom: 15px !important;
    margin-bottom: 30px;
}

.career-job-script .rbox-job-shortdesc {
    color: #37265b;
    font-weight: 500;
}

.career-job-script .rbox-job-title {
    color: #37265b;
}

.wpcp-carousel-section .wpcp-single-item img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.enroll-title-box {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    text-align: center;
    padding: 30px 180px;
    z-index: 999;
    position: relative;
    background: #fff;
    margin-left: 40px;
    margin-right: 40px;
}

.enroll-title-box h1 {
    color: #4b1469;
    font-weight: 800;
}

.container.enroll-links {
    margin-top: 80px;
    margin-bottom: 80px;
}

.enroll-dept-title {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: #4abab8;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding: 8px 16px;
}

.enroll-link-box ul {
    padding-left: 0;
    list-style: none;
}

.enroll-pagetitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
}

.enroll-link-box li {
    border-bottom: 4px solid #4abab8;
    padding: 10px 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-bottom: 13px;
    background: #fff;
}

.enroll-pagetitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
}

.enroll-subpagetitle {
    color: #929094;
    font-size: 15px;
    font-family: Montserrat;
}

.vertical-center {
    -ms-flex-align: center !important;
    align-items: center !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.enroll-link {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.enroll-link a {
    color: #4abab8;
    font-weight: 700;
}

.enroll-link i {
    padding: 3px;
    margin-right: 2px;
}

.enroll-link--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#special_education .enroll-dept-title {
    background: #e23d3b;
}

#special_education .enroll-link a {
    color: #e23d3b;
}


#special_education.enroll-link-box li {
    border-bottom: 4px solid #e23d3b;
}

#student_success .enroll-dept-title {
    background: #f5865c;
}

#student_success .enroll-link a {
    color: #f5865c;
}

#student_success.enroll-link-box li {
    border-bottom: 4px solid #f5865c;
}

#community_services .enroll-dept-title {
    background: #b7805c;
}

#community_services .enroll-link a {
    color: #b7805c;
}

#community_services.enroll-link-box li {
    border-bottom: 4px solid #b7805c;
}

#fitness_health .enroll-dept-title {
    background: #37a564;
}

#fitness_health .enroll-link a {
    color: #37a564;
}

#fitness_health.enroll-link-box li {
    border-bottom: 4px solid #37a564;
}

.governance-left ul {
    padding-left: 0;
    list-style: none;
}

.governance-left li {
    margin-bottom: 60px
}

.governance-left li:last-child {
    margin-bottom: 0px
}

.governance-title {
    position: absolute;
    background: #fff;
    padding: 20px;
    right: 0;
    color: #4b1469;
    top: 50%;
    width: 36%;
}

.governance-title h1 {
    font-weight: 900;
    font-size: 38px;
    margin-bottom: 0;
    line-height: 1.1;
}

.governance-inner {
    margin-top: 50px;
    margin-bottom: 50px;
}

.governance-left h2 {
    color: #351763;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1;
}

.governance-left p {
    font-size: 23px;
    line-height: 1.1;
    margin-bottom: 0;
}

.governance-left {
    border-right: 1px solid #588fcb;
}

.governance-dept-title {
    color: #5090d6;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.1;
}

.governance-dept-name {
    color: #351763;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 0;
}

.governance-dept-position {
    margin-bottom: 0;
}

.governance-detail-subbox {
    margin-bottom: 10px;
}


@media (min-width: 1200px) {
    .site-footer {
        position: relative;
        width: 100%;
        float: left;
    }
}

@media (min-width: 992px) {
    .single .site-main {
        flex: 0 0 75%;
        max-width: 75%;
        float: left;
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .single.single-event_listing .site-main {
        flex: 0 0 100%;
        max-width: 100%;
        float: left;
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .news-template-default .widget-area {
        flex: 0 0 25%;
        max-width: 25%;
        float: left;
        margin-top: 40px;
    }

    .news-template-default.single .site-main {
        padding-right: 40px;
        width: 75%;
    }
}

@media (min-width: 768px) {
    .wpem-col-md-4.wpem-single-event-right-content {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .card-deck .card {
        flex: auto;
        margin-right: 15px;
        margin-bottom: 20px;
        margin-left: 15px;
        max-width: 46%;
        float: left;
    }

    .card-deck .card:first-child {
        max-width: 100%;
    }

    .card-deck .card .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .card-deck .card:first-child .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .card-deck .card:first-child .col-sm-5 {
        -ms-flex: 0 0 25.666667%;
        flex: 0 0 25.666667%;
        max-width: 25.666667%;
    }

    .card-deck .card:first-child .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 72.333333%;
        max-width: 72.333333%;
    }

}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        color: #635465;
        font-weight: 700;
        font-size: 13px;
        padding: 0;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1029px;
    }
}

@media(max-width: 1199px) {
    .hero-slider {
        margin-top: -12.1%;
    }

    .alert.alert-banner {
        padding: 21px 34px 19px;
    }

    .yeled-text-sec h1 {
        padding: 0 90px;
    }

    h5.card-title {
        font-size: 19px;
        padding: 14px 0px 16px;
    }

    .parents-teacher-title h2 {
        font-size: 18px;
    }

    .families-sec p {
        font-size: 11px;
    }

    .career-sec {
        padding-left: 97px;
        padding-right: 97px;
    }

    p.newsletter-sub-detail {
        font-size: 13px;
    }

    ul.group-topic-list {
        padding-left: 0;
    }

    ul.group-topic-list li:first-child {
        padding-left: 22px;
    }

    ul.group-topic-list li a {
        font-size: 14px;
    }

    ul.group-topic-list li:first-child {
        padding-left: 2px;
        padding-right: 10px;
        border-left: 0;
    }

    ul.group-topic-list li:nth-child(3) {
        padding-left: 8px;
        padding-right: 0px;
    }

    ul.group-topic-list li:nth-child(4) {
        padding-left: 11px;
        padding-right: 10px;
    }

    ul.group-topic-list li:last-child {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-child .news-content-excerpt .news-more-link {
        left: 29.6%;
    }

    .page-template-community-services ul.group-topic-list li:nth-child(3) {
        padding-left: 23px;
        padding-right: 20px;
    }

    .group-title-list li {
        padding-left: 12px;
        padding-right: 10px;
        text-transform: uppercase;
        font-size: 10.79px;
    }

    .page-template-community-services ul.group-topic-list li:nth-child(2) {
        width: 25.6%;
        padding-left: 12px;
    }

    .page-template-community-services ul.group-topic-list li:nth-child(3) {
        width: 35.4%;
        padding-left: 13px;
        padding-right: 10px;
    }

    .page-template-community-services ul.group-topic-list li {
        padding-left: 2px;
        padding-right: 10px;
    }

    .page-template-community-services .group-title-list li:first-child {
        padding-left: 0;
    }

    .page-template-community-services .group-title-list li {
        padding-left: 0;
    }

    .jumbo-container .text-container {
        width: 36%;
    }

    .card-title-main h1 {
        padding: 0 18px;
    }

    ul.group-sub-topic-list li {
        padding-left: 10px;
        padding-right: 10px;
    }

    .owl-carousel .item .carousel-content {
        bottom: 23.8%;
        width: 50%;
        padding-left: 5%;
    }

    .page-template .news .news-title a,
    .home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details .wpem-event-title .wpem-heading-text {
        font-size: 17px;
    }

    .news-page-detail .news-col-2 {
        width: 45%;
    }
}

@media(max-width: 991px) {
    .navbar .container {
        position: relative;
    }

    .navbar-toggler {
        padding: 0;
        border: 0;
        float: right;
        right: 0;
    }

    .owl-carousel .item h4 {
        font-size: 29px;
    }

    .owl-carousel .item .carousel-content {
        bottom: 23.8%;
        width: 67%;
        padding-left: 9%;
        padding-right: 40px;
    }

    .alert.alert-banner {
        font-size: 16px;
    }

    .yeled-text-sec h1 {
        padding: 0 0px;
    }

    .card-body .list-group-flush .list-group-item {
        font-size: 14px;
    }

    .parents-teacher-ul>li {
        font-size: 11px;
    }

    .btn-primary {
        padding: 4px 17px;
    }

    .counts {
        width: 127px;
        height: 127px;
        font-size: 30px;
        line-height: 127px;
    }

    .news-content-excerpt .news-more-link {
        left: 0;
        top: auto;
    }

    .news .news-title a,
    .page-template .news .news-title a {
        font-size: 18px;
    }

    .news .news-title,
    .page-template .news .news-title {
        font-size: 12px;
        width: 100%;
    }

    .career-sec {
        padding-left: 57px;
        padding-right: 57px;
    }

    .home a.rbox-opening-li-title {
        font-size: 19px;
    }

    .newsletter-sub {
        padding: 40px 30px 30px;
    }

    .site-footer .upper-footer {
        padding: 0;
    }

    #gmap_canvas,
    .mapouter,
    .gmap_canvas {
        width: 360px !important;
        height: 500px !important;
        float: left;
    }

    ul.group-topic-list {
        padding-left: 0;
    }

    ul.group-topic-list li:first-child {
        padding-left: 22px;
    }

    ul.group-topic-list li a {
        font-size: 14px;
    }

    ul.group-topic-list li:first-child {
        padding-left: 2px;
        padding-right: 10px;
        border-left: 0;
    }

    ul.group-topic-list li:nth-child(3) {
        padding-left: 8px;
        padding-right: 0px;
    }

    ul.group-topic-list li:nth-child(4) {
        padding-left: 11px;
        padding-right: 10px;
    }

    .page-child .news-content-excerpt .news-more-link {
        left: 29.6%;
    }

    .page-template-special-education ul.group-topic-list li:nth-child(3) {
        width: 17.4%;
        padding-left: 23px;
        padding-right: 20px;
    }

    .page-template-special-education ul.group-topic-list li:first-child {
        padding-left: 2px;
        padding-right: 0px;
        width: 20.1%;
    }

    .page-template-special-education ul.group-topic-list li:nth-child(2) {
        width: 15.6%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-template-special-education ul.group-topic-list li:nth-child(4) {
        width: 19.8%;
        padding-left: 11px;
        padding-right: 10px;
    }

    .page-template-special-education ul.group-topic-list li:last-child {
        text-align: center;
        padding-left: 27px;
        padding-right: 30px;
    }

    .page-template-fitness-center ul.group-topic-list li:last-child {
        padding-left: 22px;
        padding-right: 20px;
        width: 22%;
    }

    .page-template-fitness-center ul.group-topic-list li:nth-child(2) {
        width: 27.6%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .page-template-community-services .group-title-list li:first-child {
        padding-left: 0;
    }

    .page-template-community-services .group-title-list li {
        padding-left: 0;
    }

    .group-title-list li {
        font-size: 12.79px;
    }

    .single .site-main {
        float: left;
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .single .widget-area {
        float: left;
        margin-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        display: none;
    }

    .jumbo-container .text-container {
        top: 370px;
        width: 40%;
    }

    .jumbo-container .text-container h1 {
        font-size: 34px;
    }

    .message-text-container {
        padding: 30px 5px 60px;
    }

    .card-title-main h1 {
        font-size: 15px;
        padding: 0 8px;
    }

    .card-title-main {
        min-height: 76px;
    }

    .custom-card {
        margin-bottom: 30px;
    }

    ul.group-sub-topic-list li {
        padding-left: 0;
        padding-right: 0;
        width: 25%;
    }

    ul.group-sub-topic-list li a {
        font-size: 13px;
    }

    ul.group-sub-topic-list {
        padding-left: 0;
    }

    .pl-17 {
        padding-left: 0;
    }

    .families-sec img {
        margin-top: -41px;
    }

    .home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-banner .wpem-event-banner-img {
        width: 73px;
    }

    .site-footer a.career-a {
        bottom: -30px;
    }

    .news-page-detail .news-col-2 {
        width: 44%;
    }

    .news-page-detail .news .news-title {
        font-size: 13px;
        line-height: 18px;
        min-height: 54px;
    }

    .news-page-detail .news .news-title a {
        font-size: 13px;
        line-height: 15px;
    }

    .career-text-box {
        padding: 30px;
        width: 70%;
    }

    .career-job-script .rbox-opening-li-title,
    .career-job-script .rbox-opening-li a {
        font-size: 25px;
    }

    .enroll-title-box {
        padding: 30px 50px;
    }

    .enroll-pagetitle {
        font-size: 16px;
    }

    .enroll-subpagetitle {
        font-size: 13px;
    }

    .enroll-link {
        font-size: 14px;
    }
}

@media(max-width: 767px) {
    .navbar .container {
        padding: 0 13px;
    }

    .navbar-brand img {
        margin-left: 0;
        height: 48px;
        margin-top: -8px;
    }

    .owl-carousel .owl-item img {
        height: auto;
        object-fit: cover;
    }

    .hero-slider {
        margin-top: 0;
    }

    .owl-carousel .item h4 {
        font-size: 22px;
        line-height: 19px;
        width: 100%;
    }

    .covid-icon {
        width: 27px;
        margin-right: 7px;
    }

    .alert.alert-banner {
        padding: 21px 14px 19px;
    }

    .yeled-text-sec h1 {
        font-size: 19px;
        line-height: 27px;
    }

    .yeled-text-sec {
        padding: 43px 0 43px;
    }

    .service-sec .heading-title h2 {
        font-size: 27px;
    }

    h5.card-title {
        word-spacing: 0;
        padding: 14px 0px 14px;
        min-height: auto;
    }

    .card-deck {
        padding-left: 15px;
        padding-right: 15px;
    }

    .count-sec {
        margin-bottom: 30px;
    }

    .wpem-event-listings.wpem-event-listing-list-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-date {
        display: block;
    }

    .home .wpem-event-listings .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
        padding-left: 52px;
    }

    .career-sec {
        padding-left: 20px;
        padding-right: 20px;
    }

    .career-sec .heading-title h2 {
        font-size: 26px;
    }

    .second-first {
        position: relative;
        padding-left: 15px;
    }

    .second-first:before {
        width: 0;
    }

    .footer-third {
        margin-top: 20px;
    }

    ul.footer-legal {
        text-align: center;
    }

    .site-rights {
        text-align: center;
    }

    .footer-legal li:last-child {
        margin-right: 0;
    }

    .families-sec img {
        max-height: 281px;
        width: 100%;
    }

    #home-news-list:before {
        background: 0;
    }

    #gmap_canvas,
    .mapouter,
    .gmap_canvas {
        width: 100% !important;
        height: 500px !important;
        float: left;
    }

    ul.group-topic-list li,
    ul.group-topic-list li:nth-child(2),
    ul.group-topic-list li:nth-child(3),
    ul.group-topic-list li:nth-child(4),
    .page-template-special-education ul.group-topic-list li:first-child,
    .page-template-special-education ul.group-topic-list li:nth-child(2),
    .page-template-special-education ul.group-topic-list li:nth-child(3),
    .page-template-special-education ul.group-topic-list li:nth-child(4),
    .page-template-fitness-center ul.group-topic-list li,
    .page-template-fitness-center ul.group-topic-list li:nth-child(2),
    .page-template-fitness-center ul.group-topic-list li:nth-child(3),
    .page-template-fitness-center ul.group-topic-list li:last-child,
    .page-template-community-services ul.group-topic-list li,
    .page-template-community-services ul.group-topic-list li:nth-child(2) {
        width: 49%;
    }

    ul.group-topic-list li:last-child {
        border-left: 0 solid #9a9a9a;
        width: 100%;
    }

    ul.group-topic-list li:nth-child(3) {
        border-left: 0 solid #9a9a9a;
    }

    .page-child .news-content-excerpt .news-more-link {
        left: 41.6%;
        top: 69%;
    }

    .page-sidebar {
        padding-right: 15px;
        padding-left: 15px;
    }

    .page-template-special-education .group-title-list li:first-child {
        margin-left: 0;
        padding-left: 15px;
    }

    h1.main-heading {
        font-size: 30px;
    }

    a.btn-jump-section {
        font-size: 13px;
    }

    .border-news {
        border-right: 0px solid #588fcb;
    }

    .innerpage-event {
        margin-top: 40px;
    }

    .wpem-event-listings.wpem-event-listing-list-view .wpem-event-layout-wrapper .wpem-event-infomation .wpem-event-details {
        padding-left: 60px;
        margin-bottom: 60px;
    }

    .group-title-list li:first-child {
        padding-left: 13px;
    }

    .page-template-community-services ul.group-topic-list li:nth-child(3) {
        width: 100%;
    }

    .video-section video {
        width: 100%;
    }

    .jumbo-container .text-container {
        width: 64%;
        top: 31%;
    }

    .jumbo-container .text-container h1 {
        font-size: 26px;
    }

    .jumbo-container {
        position: relative;
    }

    .message-text-container {
        padding: 30px 0 60px;
    }

    .message-text-container h1 {
        font-size: 21px;
        line-height: 29px;
    }

    .card-title-main {
        min-height: auto;
    }

    .non-feature-card.featured-card-group .card-body img {
        padding-right: 15px;
    }

    ul.group-sub-topic-list li {
        width: 50%;
        margin-bottom: 10px;
    }

    ul.group-sub-topic-list li:nth-child(3) {
        border-left: 0;
    }

    .owl-carousel .item .carousel-content h4 {
        font-size: 32px;
        line-height: 40px;
    }

    .owl-carousel .item .carousel-content {
        bottom: 34.8%;
        width: 100%;
        padding-left: 9%;
        padding-right: 40px;
    }

    .pl-17 {
        padding-left: 37px;
    }

    .card-deck .card {
        max-width: 100%;
    }

    .families-sec img {
        margin-top: 0;
    }

    .parents-teacher-title h2 {
        text-align: center;
        padding-left: 0;
    }

    .parents-teacher-title {
        padding-left: 0;
    }

    .card-img-top {
        max-height: 130px;
        width: 100%;
    }

    .parents-teacher .row .col-sm-6:first-child {
        padding-left: 15px;
    }

    .blank-purple-section {
        display: none;
    }

    .parents-teacher .row .col-sm-6:last-child {
        padding-right: 15px;
    }

    .mlr-8 {
        margin: 0 0;
    }

    .families-sec {
        margin: 0px 0 30px;
    }

    .news-page-detail .news-col-2 {
        width: 92%;
    }

    .news-event-title h2 {
        font-size: 27px;
    }

    .career-text-box {
        width: 92%;
        padding: 20px 30px;
    }

    .career-job-script .rbox-opening-li-title,
    .career-job-script .rbox-opening-li a {
        font-size: 20px;
    }

    .news-event-title h2:after {
        padding-top: 10px;
    }

    .career-text-box p {
        margin-top: 14px;
    }

    .career-banner img {
        height: 460px;
        object-fit: cover;
    }
}

@media (min-width: 576px) {
    .non-feature-card .card-deck .card {
        margin-right: 15px;
        margin-left: 15px;
    }
}

@media(max-width: 575px) {
    .parent-page-card .card-img-top {
        width: 100%;
    }

    .map-img {
        margin-top: 30px;
    }

    .site-footer a.career-a {
        position: relative;
        bottom: -6px;
    }

    .footer-first {
        padding-left: 0;
        margin-top: 22px;
    }

    .footer-third {
        padding-left: 0;
    }

    .footer-third {
        margin-top: 25px;
    }

    .footer-sitelinks {
        text-align: center;
    }

    .site-rights {
        margin-right: 0;
        margin-top: 12px;
    }

    .card-deck {
        padding-left: 0;
        padding-right: 0;
    }

    .news-page-detail .news-col-2 {
        width: 95%;
    }

    .news-page-detail .news .news-title,
    .news-page-detail .news-content-excerpt .news-short-content {
        min-height: auto;
    }

    .image-gallery {
        margin-top: 40px
    }

    .enroll-title-box h1 {
        font-size: 25px;
    }

    .enroll-title-box {
        padding: 20px 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

/*This style sheet is modified by Jorge Escobar just as my notes*/

/*******Home Page******/

.top-arrow,
.down-arrow {
    background: #588fcb;
    color: white;
    padding: 12px;
    margin: auto !important;
    display: block !important;
    width: 40px;
    border-radius: 5px;
    vertical-align: middle;
}

.top-arrow:hover,
.down-arrow:hover {
    background: #37265b;
}

/*Header menu*/
@media (max-width: 767px) {
    .navbar-brand img {
        display: block;
    }

    .navbar {
        text-align: center;
    }

    .mb-10 {
        margin: 20px auto;
        display: table;
        position: relative;
    }

}

/* .fa-phone-alt,
.fa-envelope,
.fa-search {
    display: initial !important;
    border-radius: 50px;
} */

.card-body .list-group-flush .list-group-item:hover {
    background: #E0E9F6 !important;
}

/*covid row section*/
.read-more-arrow {
    width: 45px;
    vertical-align: middle;
    margin-left: 20px;
}

/* Boxs Section*/

.card-body {
    padding-bottom: 0px !important;
}

.section6-bg {
    background: purple;
}

/*Fitenss & Health*/

.fitness-health ul li a {
    font-family: "Font Awesome 5 Free";
    top: 0;
    left: -5px;
    padding-right: 10px;
    content: "\f105";
    color: #37a564;
}

/*Link Hover Color*/
/*.card-body .list-group-flush .list-group-item a:hover{
	color: #37265b !important;
}*/

/*button Hover*/
.btn-primary:hover {
    background: #588fcb;
}

/*Login Button Hover*/
.navbar .purple-btn:hover {
    background: #588fcb;
}


/*Career Opportunity Sections*/
.career-sec {
    background: #37265b;
    position: relative;
    margin-top: -5px;
    padding-top: 30px;
    padding-bottom: 30px;
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/purple-bg.png);*/
    background: url(../../images/purple-bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


/*Newsletter Button Hover*/
.es_subscription_form input[type="submit"]:hover {
    background: #588fcb;
}

/*Footer Hover Links*/
.site-footer a:hover {
    color: #588fcb;
}

/*Footer BG*/

.site-footer {
    /*background: url(https://yvy.yeled.org/wp-content/uploads/2020/09/grey-bg.png);*/
    background: url(../../images/grey-bg.png);
    background-color: #E3E4E3;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

/*Footer button*/

element.style {}

.site-footer a.doe-a:hover {
    background: #37265b;
    color: #FFFFFF;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.site-footer a.career-a:hover {
    background: #37265b;
    color: #FFFFFF;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.site-footer a.footer-login:hover {
    background: #588fcb;
    color: #FFFFFF;
    padding: 10px 27px 8px;
    border-radius: 20px;
    border: 2px solid #588fcb;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
}

.footer-fas {
    color: #588fcb;
}

.footer-links-ul li {
    position: relative;
    padding-left: 18px;
    line-height: 17px;
    margin-bottom: none;
}

/*.footer-links-ul li:before{
	font-family: FontAwesome;
    top:0;
    left:-5px;
    padding-right:10px;
    content: "\f105" !important; 
	color: #588fcb;
    text-align: center;
}
*/

/*footer Bottom Links*/
.footer-sitelinks li a:hover {
    color: #000000;
}

.site-footer .footer-legal a:hover {
    color: #000000;
}

.footer-first {
    padding-left: 11px;
    height: 100%;
}


ul.social-links li {
    margin-right: 15px;
}

@media (max-width: 767px) {
    .desktop-footer {
        display: none;
    }

    .col-md-2 {
        margin: 10px;
    }

    .footer-logo img {
        width: 250px;
        display: block;
        margin: auto;
    }

    .col-md-12 {
        text-align: center;
    }

    .mt-10 {
        margin-top: 30px;
    }

    ul.social-links {
        text-align: center;
    }
}

@media (min-width: 767px) {
    .mobile-footer {
        display: none;
    }

}

/*Top Button*/
.site-footer .goto-top {
    right: 1.5%;
    bottom: 1.5% !important;
}

/*pop up form*/

.pop-htr-form {
    background: #518ECC;
    color: white;
    text-align: center;
    display: block;
    padding: 20px 0px;
}

.popup-form-title {
    font-size: 34px;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-family: 'Nunito', sans-serif;
    color: white;
}

.popup-form-text {
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
}

.wpcf7 input,
.wpcf7-textarea {
    width: 100%;
    border-radius: 25px;
}

select.form-control {
    color: red;
}

label {
    font-family: 'Nunito', sans-serif;
    color: #37265b;
    font-size: 15px;
    font-weight: 700;
}

input[type="submit"i] {
    border-radius: 33px;
    padding: 11px 26px;
    font-size: 13px;
    margin-left: -6px !important;
    background: #37265b;
    border: 0;
    color: #fff;
    font-weight: 700;
    margin: 0 auto !important;
    width: 160px;
}

select {
    width: 300px;
    border: 1px solid #e6e6ea;
    background: #518ECC;
    border-radius: 25px;
    padding: 10px;
    color: white;
    margin: 0px;
}

option {
    padding-right: 10px;
}

/*popup phone*/

.spu-box.spu-position-bottom-bar .spu-content>*,
.spu-box.spu-position-top-bar .spu-content>* {
    margin-left: 0px;
}

/*Event Section*/

.event-title {
    font-family: 'Nunito', sans-serif;
    font-size: 21px;
    line-height: 25px;
    color: #588fcb;
    text-decoration: none !important;
    letter-spacing: 0.1px;
    font-weight: 700;
}

.event-title a:hover {
    color: #37265b;
}

.event-title a {
    color: #588fcb;
}

.fa-map-marker {
    color: #37265b;
}

.grid-date-post a {
    color: #588fcb !important;
}

.event-more-link {
    color: #37265b;
    font-weight: 800;
    font-size: 13px;
    background: transparent;
    padding: 0;
    margin-left: 0;
    position: relative !important;
    left: 0 !important;
    text-transform: capitalize;
}

.event-register {
    background: transparent;
    color: #37265b;
    padding: 5px 20px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    float: right;
}

.event-register:hover {
    background: #37265b;
    color: #ffffff;
    padding: 5px 20px;
    border-radius: 20px;
    border: 2px solid #37265b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    float: right;
}

/*Career Oportunities Section*/

.job-list-ul li {
    border-bottom: 1px solid #ccc;
    padding-bottom: 0px;
    width: 87%;
    margin-bottom: 10px;
    position: relative;
    list-style-type: none;
}

.job-time {
    color: #588fcb;
    font-size: 8px;
    margin-bottom: 0px;
}


.fas-title {
    color: #588fcb;
}

.fas-title:hover {
    color: #588fcb;
}

.job-description {
    color: #fff;
    font-size: 12px;
}

/*Boxes Height*/
.show {
    height: auto;
}

@media (max-width: 767px) {
    .earlychild-box {
        height: auto !important;
    }
}

/*footer social links hover*/

.fa-facebook-f:hover {
    color: #37265b;
}

.fa-instagram:hover {
    color: #37265b;
}

.fa-linkedin-in:hover {
    color: #37265b;
}

@media (max-width: 767px) {
    .col-sm-6 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/*Image Desktop and mobile*/

@media (min-width: 767px) {
    .img-desktop {
        display: none;
    }
}

@media (max-width: 767px) {
    .img-mobile {
        display: none;
    }
}

.owl-buttons {
    display: none;
}

.owl-carousel:hover .owl-buttons {
    display: block;
}

.owl-item {
    text-align: center;
}

.owl-theme .owl-controls .owl-buttons div {
    background: transparent;
    color: #869791;
    font-size: 40px;
    line-height: 207px;
    margin: 0;
    padding: 0 60px;
    position: absolute;
    top: 0;
}

.owl-theme .owl-controls .owl-buttons .owl-prev {
    left: 0;
    padding-left: 20px;
}

.owl-theme .owl-controls .owl-buttons .owl-next {
    right: 0;
    padding-right: 20px;
}