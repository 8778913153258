.sidebar-moreposts__container {
	h3 {
		margin: 25px 0 10px;
		font-weight: 700;
		color: $brand-purple;
		font-size: 27px;
	}
	hr {
		border-color: $accent-blue;
		border-width: 2px;
		margin: 10px 0 20px;
	}
}
