.page-sidebar {
    padding: 20px;
    .staff-main-director,
    .staff-members {
        .svg-inline--fa {
            margin-right: 10px;
        }
    }

    .staff-director {
        .director-email,
        .director-phone,
        .director-fax {
            margin: 8px 0;
            font-size: 18px;
            color: $gray-text;
            &:hover {
                color: $accent-blue;
            }
        }
    }

    .director-position,
    .director-position-card {
        padding-left: 27px;
        font-size: 16px;
        font-weight: 400;
        transition: color 0.3s ease;
        line-height: 1;
    }

    .collapsed .director-position-card {
        color: $gray-text;
    }

    .director-name {
        font-size: 24px;
        padding-left: 27px;
        position: relative;
        .svg-inline--fa {
            left: 0;
            position: absolute;
            color: $accent-blue;
            margin-right: 4px;
        }
    }

    .staff-card-title {
        margin-bottom: 0!important;
        position: relative;
        padding-top: 0;
        font-weight: 700;
        .fa-caret-down {
            position: absolute;
            right: 0;
            transition: all 0.3s ease;
        }
    }

    .card-header:not(.collapsed) {
        .staff-card-title {
            .fa-caret-down {
                transform: rotate(180deg);
            }
        }
    }

    .staff-member-card {
        padding-bottom: 16px;
    }

    .director-email-card,
    .director-phone-card {
        font-size: 16px;
    }
    .sell-all-btn {
        margin-top: 20px;
    }

    .additional-links {
        position: relative;
        padding-left: 24px;
        .arrow {
            position: absolute;
            left: 0;
            top: 4px;
        }
        a {
            font-size: 16px;
        }
        .svg-inline--fa {
            color: $accent-blue
        }
    }

    .read-more-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .read-more-arrow {
            width: 60px;
            margin-left: 8px;
        }
    }

    .wpem-event-date-time {
        margin-left: 0!important;
    }

    .wpem-event-infomation {
        width: 100%!important;
    }

    // Jobs

    .sidebar-jobs {
        .sidebar-main-heading {
            margin-bottom: 24px;
        }
        .jobs-title {
            color: $gray-text;
            font-size: 16px;
        }
    }

    .jobs--job {
        border-bottom: solid 1px $accent-blue;
        padding: 20px 0;
    }

    .jobs--job-title a {
        color: $brand-purple;
        font-size: 18px;
        font-weight: bold;
    }

    .jobs--job-location {
        font-size: 16px;
        color: $gray-text;
    }

    .jobs--job-link {
        margin-bottom: 0;
        i, a {
            color: $accent-blue;
            border-color: $accent-blue;
            font-weight: 800;
        }
    }

    .sell-all-btn {
        font-size: 18px;
    }

    .sidebar-main-heading {
        font-size: 18px;
        margin-top: 24px;
        margin-right: -20px;
        width: calc(100% + 20px);
    }

}

.event-listing-view-sidebar {
  margin: 0;
}

.sidebar-events{
    #event-listing-view{
        .wpem-event-box-col{
            margin-bottom: 0;
        }
        .wpem-event-infomation{
            float: none;
        }
        .wpem-event-details{
            float: none;
            padding-right: 0 !important;
            .wpem-event-banner{
                padding-right: 0 !important;
                .wpem-event-banner-img{
                    width: 100%;
                }
            }
        }
        .js-collapsable-box-event{
            display: none;
        }
    }
}