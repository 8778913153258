.navbar-container-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}


.navbar--contact-buttons-phone {
    position: relative;
    display: flex;
    justify-content: center;
}

.navbar--contact-buttons-phone-popup {
    border-top: solid 12px $brand-blue;
    position: absolute;
    width: 240px;
    bottom: -142px;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    
    transition: transform 0.2s ease, opacity 0.2s ease;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    height: 0;
    z-index: 10;
    &.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        height: auto;
        &:before,
        .phone-popup-close,
        .phone-number,
        .popup-phone-title {
            display: block;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: -30px;
        left: calc((100% - 48px) / 2);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 24px 20px 24px;
        border-color: transparent transparent $brand-blue transparent;
    }
    .phone-popup-close {
        position: absolute;
        top: 24px;
        right: 16px;
        &:before,
        &:after {
            content: '';
            border-radius: 1px;
            background-color: $dirty-purple;
            width: 2px;
            height: 12px;
            position: absolute;
        }
        &:before {
            transform: rotate(-45deg);
        }
        &:after {
            transform: rotate(45deg);
        }

    }
    .popup-phone-title {
        font-weight: 800;
        margin-top: 8px;
        margin-bottom: 0;
        color: $dirty-purple;
        &:first-of-type {
            margin-top: 0;
        }
    }
    .arrow.right {
        padding: 3px;
        margin-right: 8px;
    }
    .phone-number {
        padding-left: 16px;
        margin-bottom: 0;
        font-weight: bold;
    }
    &:before,
    .phone-popup-close,
    .phone-number,
    .popup-phone-title {
        display: none;
    }
}

.search-bar {
    position: absolute;
    width: 100%;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-80px);
    opacity: 0;
    .search-bar--input {
        width: 100%;
        height: 80px;
        border-radius: 0;
        padding: 16px 80px;
        font-size: 32px;
    }
    &.active {
        transform: translateY(0);
        opacity: 1;
    }
}

#main-menu-toggle {
	&.navbar-collapse {
		justify-content: center;
	}
}

#menu-main-menu {
	.menu-item {
		margin: 0 12px;
        border-bottom: solid transparent 3px;
        transition: all 0.3s ease;
		a {
			color: #635465;
			font-size: 16px;
            font-weight: 800;
			padding: 0;
		}
		&:hover,
		&.current-menu-item {
            border-bottom-color: $brand-blue;
            font-weight: 700;
            a {
                color: #37265b;
            }
        }
        &.hide-desktop {
            @include respond-above(md) {
                display: none;
            }
        }
    }
    @include respond-below(md) {
        padding-top: 8px;
        .menu-item {
            padding: 8px 0;
            &:hover,
            &.current-menu-item {
                border-bottom: none;
                a {
                    color: $brand-blue;
                }
            }
        }
    }
}

.navbar--contact-buttons-holder {
	display: flex;
	font-size: 18px;
	.navbar--contact-buttons {
		margin: 0 4px;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease;
        &:hover {
            background-color: $brand-light-blue;
        }
	}
	.navbar--contact-buttons a {
		color: $brand-blue;
        line-height: 1;
    }
    @include respond-below(md) {
        display: none;
    }
}

.navbar--login-button {
    @include respond-below(md) {
        display: none;
    }
}

.subsite-menu {
    position: relative;
}

.subsite-menu--categories {
    background-color: $brand-purple;
    .subsite-menu--categories--container {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;

    }
    .subsite-menu--category {
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #6d5d85;
        font-family: Nunito, sans-serif;
        font-size: 16px;
        font-weight: 600;
        user-select: none;
        text-align: center;
        position: relative;
        padding-left: 16px;
        &.category_is_active,
        &:hover {
            color: #fff;
        }
        .fa-caret-down {
            display: none;
            margin-right: 4px;
            position: absolute;
            left: 0;
        }
        &.category_is_active {
            .fa-caret-down {
                display: inline;
            }
        }
        &[data-site-category="subsite-category-early_childhood_education"] {
            order: 1;
        }
        &[data-site-category="subsite-category-special_education__disability_services"] {
            order: 1;
        }
        &[data-site-category="subsite-category-behavior_health"] {
            order: 2;
        }
        &[data-site-category="subsite-category-student_success"] {
            order: 3;
        }
        &[data-site-category="subsite-category-community__social"] {
            order: 4;
        }
        &[data-site-category="subsite-category-fitness__health"] {
            order: 5;
        }
        
        @include respond-below(md) {
            min-width: 240px;
        }
    }
}

.subsite-menu--sites {
    background-color: #d4d4d4;
    border-top: solid 8px $accent-blue;
    position: absolute;
    left: 0;
    width: 100%;
    // transition: all 0.2s ease;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    transition-property: opacity, visibility, height, transform;
    transition-timing-function: ease;
    transition-duration: 0.2s;
    &.is-active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        height: auto;
    }
    .subsite-menu--sites--container {
        display: none;
        min-width: 480px;
        &.category_is_active {
            display: flex;
            align-items: stretch;
        }
    }
    .subsite-menu--site {
        line-height: 1.27;
        padding: 24px 12px;
        text-align: center;
        color: $brand-purple;
        font-weight: 600;
        font-size: 20px;
        border-right: solid 1px #9e9e9e;
        user-select: none;
        transition: background-color 0.2s ease, color 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 180px;
        position: relative;
        .fa-caret-down {
            position: absolute;
            top: -20px;
            height: 46px;
            width: 46px;
            display: none;
        }
        &:last-child {
            border-right: none;
        }
        &.is-active {
            background-color: $brand-purple;
            color: #fff;
            &:hover {
                background-color: $brand-purple;
            }
            .fa-caret-down {
                display: inline;
            }
        }
        &:hover {
            background-color: $brand-purple;
            color: #fff;
        }
    }
}

.subsite-menu--sublinks {
    background-color: #f0f0f0;
    transition: all 0.2s ease;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    height: 0;
    z-index: 10;
    &.is-active,
    &.is-being-viewed:not(.user-browsing),
    &:hover {
        border-top: solid 8px $brand-purple;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        height: auto;
    }
    .subsite-menu--sublinks--container {
        display: flex;
        justify-content: space-between;
    }
    .subsite-menu--sublink {
        padding: 14px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $brand-purple;
        font-weight: 600;
        border-right: solid 1px #9e9e9e;
        transition: all 0.3s ease;
        &:last-child {
            border-right: none;
        }
        .fa-caret-down {
            position: absolute;
            top: -20px;
            height: 46px;
            width: 46px;
            color: $brand-purple;
            display: none;
        }
        &.is-active {
            .fa-caret-down {
                display: inline;
            }
        }
    }
}

.subsite-menu--categories,
.subsite-menu--sites,
.subsite-menu--sublinks {
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        display: none;
    }
}
