/*!
Theme Name: Yeled
Theme URI: https://urbilabs.com
Author: Urbi Labs
Author URI: https://urbilabs.com
Description: Custom WordPress theme created by Urbi Labs.
Version:      1.0.0
*/

// Libs
//@import 'css/libs/reset';
//@import 'css/libs/slick';
//@import 'css/libs/fancybox';
@import "libs/wp-core";

// Includes
@import "includes/vars";
@import "includes/fonts";
@import "includes/extends";

// Global
@import "global/mixins";
@import "global/buttons";
@import "global/forms";
@import "global/typography";
@import "global/structure";
@import "global/header";
@import "global/footer";
@import "global/variables";
@import "global/location";

// Inherited stylesheets
@import "inherited/bootstrap.css";
/*@import "inherited/owl.carousel.min.css";
@import "inherited/owl.theme.default.min.css";*/
@import "inherited/custom.css";
@import "subsiteMenu.scss";

@import "modules/blocks/yeled-faqs";
@import "modules/blocks/yeled-list";
@import "modules/blocks/yeled-news";
@import "modules/blocks/yeled-department-cards";
@import "modules/blocks/yeled-cards-with-list";
@import "modules/module-career-opportunities";

@import "modules/module-color-block";
@import "modules/module-feature-image";
@import "modules/module-about-cards";
@import "modules/module-department-sidebar";
@import "modules/module-hero";
@import "modules/module-cards";
@import "modules/module-alert-banner";
@import "modules/news-events";
@import "modules/professional-news-events";
@import "modules/module-career-opportunities";
@import "modules/module-news";
@import "modules/module-sidebarposts";
@import "modules/module-modals";
/*
@import "pages/page-jobs";
@import "pages/page-job-detail";
@import "pages/tpl-department";*/
@import "pages/tpl-about";
/*@import "pages/page-governance";*/
/*@import "pages/page-home";*/
/*@import "pages/single";
@import "pages/tpl-news-events";
@import "pages/tpl-findservice";
@import "pages/page-search";
@import "pages/single-event";
@import "pages/governance.css";*/

// Partials
/*@import "modules/blocks/yeled-faqs";
@import "modules/blocks/yeled-list";
@import "modules/blocks/yeled-news";
@import "modules/blocks/yeled-department-cards";
@import "modules/blocks/yeled-cards-with-list";

@import "modules/module-color-block";
@import "modules/module-feature-image";
@import "modules/module-about-cards";
@import "modules/module-department-sidebar";
@import "modules/module-hero";
@import "modules/module-cards";
@import "modules/module-alert-banner";
@import "modules/news-events";
@import "modules/professional-news-events";
@import "modules/module-career-opportunities";
@import "modules/module-news";
@import "modules/module-sidebarposts";
@import "modules/module-modals";
*/
// Pages

/*@import "pages/page-jobs";
@import "pages/page-job-detail";
@import "pages/tpl-department";
@import "pages/tpl-about";
@import "pages/page-governance";
@import "pages/page-home";
@import "pages/single";
@import "pages/tpl-news-events";
@import "pages/tpl-findservice";
@import "pages/page-search";
@import "pages/single-event";
@import "pages/governance.css";

*/

