// --------------------------------------------------
// Global Extends
// --------------------------------------------------

.clearfix {

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.accessible-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    top: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
