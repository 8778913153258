.accordion {
    .card-header {
        padding: .75rem 16px .75rem 0!important;
        .icon {
            position: absolute;
            right: 0;
            top: 17px;
            width: 20px;
            height: 20px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                height: 4px;
                background-color: $brand-purple;
                width: 20px;
                transition: all .3s ease-in-out;
            }
        }
        &.collapsed {
            .icon:after {
                transform: rotate(90deg);
            }
        }
    }
}