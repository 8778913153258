//general template adjustment
body.page-template-tpl-events-detail {
	background: #fafafa;
	.subsite-menu {
		display: none;
	}
	.navbar {
		margin-top: 25px;
	}
}

.page-template-tpl-about {
	.career-banner-div {
		.career-banner {
			padding: 160px 0 60px;
		}
	}
}

.event-detail-banner {
	overflow: hidden;
	img {
		top: 0;
		position: absolute;
		width: 100%;
		min-height: 160vh;
		@include respond-below(sm) {
			max-height: inherit;
		}
	}
	.over-element {
		.card {
			box-shadow: -3px 3px 20px 0 rgba(0, 0, 0, 0.16);
			border: none;
			.card-header {
				background-color: $blue-alt;
				border: none;
				text-align: center;
				h1 {
					font-size: 40px;
					font-weight: 700;
					color: #fff;
				}
				span {
					color: #fff;
				}
			}
			.card-body {
				color: #868586;
				padding-bottom: 3rem!important;
			}
		}
	}
}
