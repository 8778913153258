/* Infowindow Roboto font override */
.gm-style div,
.gm-style span,
.gm-style label,
.gm-style a {
	font-family: Arial, Helvetica, sans-serif;
}

/* InfoBubble font size */
.bh-sl-window {
	font-size: 13px;
}

.bh-sl-error {
	clear: both;
	color: #ae2118;
	float: left;
	font-weight: bold;
	padding: 10px 0;
	width: 100%;
}

#bh-sl-map-container {
	box-sizing: border-box;
	width: 100%;
	.bh-sl-loc-list {
		font-size: 13px;
		height: 530px;
		overflow-x: auto;
		ul li {
			background-color: #fff !important;
			list-style: none;
			position: relative;
			.list-label {
				display: none;
			}
			.loc-name {
				color: #37265b;
				font-weight: 700;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 4px;
			}
			&.list-focus {
				padding-left: 13px;
				&:before {
					content: "";
					position: absolute;
					display: block;
					left: 0;
					top: 0;
					width: 7px;
					height: 59px;
					background: #588fcb;
				}
			}
		}
	}
	#bh-sl-map {
		height: 430px;
		width: 100%;
	}
}

/* Avoid image issues with Google Maps and CSS resets */
.bh-sl-map-container img {
	border-radius: 0 !important;
	box-shadow: none !important;
	max-height: none !important;
	max-width: none !important;
}

.bh-sl-container {
	box-sizing: border-box;
	color: #555;
	float: left;
	font: normal 14px/1.4 Arial, Helvetica, sans-serif;
	padding: 0 15px;
	width: 100%;
	/* Avoid issues with Google Maps and CSS frameworks */
}
.bh-sl-container > * {
	box-sizing: content-box !important;
}
.bh-sl-container .bh-sl-form-container {
	clear: left;
	float: left;
	margin-top: 15px;
	width: 100%;
}
.bh-sl-container .form-input {
	float: left;
	margin-top: 3px;
	width: 100%;
}
@media (min-width: 768px) {
	.bh-sl-container .form-input {
		width: auto;
	}
}
.bh-sl-container .form-input label {
	display: block;
	font-weight: bold;
	width: 100%;
}
@media (min-width: 768px) {
	.bh-sl-container .form-input label {
		display: inline-block;
		width: auto;
	}
}
.bh-sl-container .form-input input,
.bh-sl-container .form-input select {
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 4px;
	font: normal 14px/1.4 Arial, Helvetica, sans-serif;
	margin: 15px 0;
	padding: 6px 12px;
	width: 100%;
	-webkit-border-radius: 4px;
}
@media (min-width: 768px) {
	.bh-sl-container .form-input input,
	.bh-sl-container .form-input select {
		width: auto;
		margin: 0 15px 0 10px;
	}
}
.bh-sl-container .loc-alt-dist {
	display: none;
}
.bh-sl-container button {
	background: #00447a;
	border: none;
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	float: left;
	font: bold 14px/1.4 Arial, Helvetica, sans-serif;
	margin-top: 3px;
	padding: 6px 12px;
	white-space: nowrap;
	-webkit-border-radius: 4px;
}
.bh-sl-container .bh-sl-loading {
	// background: url(./../../images/ajax-loader.gif) no-repeat;
	float: left;
	margin: 4px 0 0 10px;
	height: 16px;
	width: 16px;
}
.bh-sl-container .bh-sl-filters-container {
	clear: both;
	float: left;
	margin: 15px 0;
	width: 100%;
}
.bh-sl-container .bh-sl-filters-container .bh-sl-filters {
	float: left;
	list-style: none;
	margin: 0 100px 0 0;
	padding: 0;
}
.bh-sl-container .bh-sl-filters-container .bh-sl-filters li {
	clear: left;
	display: block;
	float: left;
	margin: 5px 0;
	width: 100%;
}
.bh-sl-container .bh-sl-filters-container .bh-sl-filters li label {
	display: inline;
	vertical-align: text-bottom;
}
.bh-sl-container .bh-sl-filters-container .bh-sl-filters li input {
	display: block;
	float: left;
	margin-right: 8px;
}
.bh-sl-container .bh-sl-filters-container .bh-sl-filters li select {
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 4px;
	font: normal 14px/1.4 Arial, Helvetica, sans-serif;
	padding: 6px 12px;
	-webkit-border-radius: 4px;
}
.bh-sl-container .bh-sl-map-container {
	clear: left;
	float: left;
	margin-top: 27px;
	width: 100%;
}
@media (min-width: 1024px) {
	.bh-sl-container .bh-sl-map-container {
		margin-bottom: 60px;
	}
}
.bh-sl-container .bh-sl-map-container a {
	color: #005293;
	text-decoration: none;
}
.bh-sl-container .bh-sl-map-container a:active,
.bh-sl-container .bh-sl-map-container a:focus,
.bh-sl-container .bh-sl-map-container a:hover {
	text-decoration: underline;
}
.bh-sl-container .bh-sl-loc-list {
	font-size: 13px;
	height: 530px;
	overflow-x: auto;
	width: 100%;
}
@media (min-width: 1024px) {
	.bh-sl-container .bh-sl-loc-list {
		width: 30%;
	}
}
.bh-sl-container .bh-sl-loc-list ul {
	display: block;
	clear: left;
	float: left;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
}
.bh-sl-container .bh-sl-loc-list ul li {
	border: 1px solid #fff;
	/* Adding this to prevent moving li elements when adding the list-focus class*/
	box-sizing: border-box;
	clear: left;
	cursor: pointer;
	display: block;
	float: left;
	width: 100%;
}
.bh-sl-container .bh-sl-loc-list .list-label {
	background: #00192d;
	border-radius: 15px;
	color: #fff;
	display: block;
	float: left;
	font-weight: bold;
	margin: 10px 0 0 15px;
	padding: 4px 7px;
	text-align: center;
	width: auto;
	min-width: 13px;
}
.bh-sl-container .bh-sl-loc-list .list-details {
	float: left;
	margin-left: 6px;
	width: 80%;
}
.bh-sl-container .bh-sl-loc-list .list-details .list-content {
	padding: 10px;
}
.bh-sl-container .bh-sl-loc-list .list-details .loc-dist {
	color: #8e8e8e;
	font-weight: bold;
	font-style: italic;
}
.bh-sl-container .bh-sl-loc-list .list-focus {
	border: 1px solid rgba(0, 82, 147, 0.4);
	transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-close-directions-container {
	height: 20px;
	position: relative;
	width: 100%;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-close-directions-container .bh-sl-close-icon {
	right: 6px;
	top: 0;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel {
	margin: 0 2%;
	/* Avoid issues with table-layout */
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel table {
	table-layout: auto;
	width: 100%;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel table,
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel td {
	vertical-align: middle;
	border-collapse: separate;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel td {
	padding: 1px;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel .adp-placemark {
	margin: 10px 0;
	border: 1px solid #c0c0c0;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-directions-panel .adp-marker {
	padding: 3px;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-noresults-title {
	font-weight: bold;
	margin: 15px;
}
.bh-sl-container .bh-sl-loc-list .bh-sl-noresults-desc {
	margin: 0 15px;
}
.bh-sl-container .loc-name {
	/* Picked up by both list and infowindows */
	font-size: 15px;
	font-weight: bold;
}
.bh-sl-container .bh-sl-map {
	float: left;
	height: 530px;
	width: 100%;
}
@media (min-width: 1024px) {
	.bh-sl-container .bh-sl-map {
		width: 70%;
	}
}
.bh-sl-container .bh-sl-pagination-container {
	clear: both;
}
.bh-sl-container .bh-sl-pagination-container ol {
	list-style-type: none;
	margin: 0;
	padding: 10px 0;
	text-align: center;
}
.bh-sl-container .bh-sl-pagination-container ol li {
	color: #005293;
	cursor: pointer;
	display: inline-block;
	font: bold 14px Arial, Helvetica, sans-serif;
	padding: 10px;
}
.bh-sl-container .bh-sl-pagination-container ol .bh-sl-current {
	color: #555;
	cursor: auto;
	text-decoration: none;
}

/* Modal window */
.bh-sl-overlay {
	// background: url(../img/overlay-bg.png) repeat;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
}
.bh-sl-overlay .bh-sl-modal-window {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px #656565;
	position: absolute;
	left: 50%;
	margin-left: -460px;
	/* width divided by 2 */
	margin-top: 60px;
	height: 620px;
	width: 920px;
	z-index: 10010;
}
.bh-sl-overlay .bh-sl-modal-window .bh-sl-map-container {
	margin-top: 50px;
	/* increase map container margin */
}
.bh-sl-overlay .bh-sl-modal-window .bh-sl-modal-content {
	float: left;
	padding: 0 1%;
	/* there's already a margin on the top of the map-container div */
	width: 98%;
}
.bh-sl-overlay .bh-sl-modal-window .bh-sl-close-icon {
	right: 22px;
	top: 13px;
}

.bh-sl-close-icon {
	cursor: pointer;
	height: 24px;
	position: absolute;
	width: 24px;
}
.bh-sl-close-icon:after,
.bh-sl-close-icon:before {
	background: #ccc;
	content: "";
	display: block;
	height: 24px;
	margin: -3px 0 0 -1px;
	position: absolute;
	bottom: 0;
	left: 50%;
	right: 3px;
	top: 3px;
	width: 3px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
.bh-sl-close-icon:hover:after,
.bh-sl-close-icon:hover:before {
	background: #b3b3b3;
}
.bh-sl-close-icon:before {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
