@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 700px;
}
}

.modal.custom-modal {
	&.show {
		height: 100%;
		background: rgba(0, 0, 0, 0.4);
	}

	.modal-header {
		background: $blue-alt;
		border: none;
		text-align: center;
		align-items: center;
		justify-content: center;
		h5 {
			font-weight: 800;
			color: #fff;
			font-size: 30px;
		}
		.close {
			position: absolute;
			right: 10px;
			top: 10px;
			color: #fff;
			opacity: 1;
			font-size: 22px;
		}
	}

	.modal-content {
		border: none;
		border-radius: 0;
		box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
	}

	.modal-body-steps {
		h4 {
			color: $brand-purple;
			font-weight: 800;
			font-size: 20px;
		}
		.btn-with-icon{
			display: flex;
			flex-direction: column;
			border-radius: 20px;
			justify-content: center;
			align-items: center;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
		}
	}
}
